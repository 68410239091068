@import '../../../style/variables.scss';
@import '../../../style/mixins.scss';

.change-history-container {
  .loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search-alert {
    color: $note-text;
  }
}
