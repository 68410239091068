@import '../../../style/variables.scss';

.btn-add-sponsor {
  border: none;
  font-family: inherit;
  font-size: 16px;
  background-color: $white;
  box-shadow: none;
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  color: $blue-primrose;
  cursor: pointer;
  padding: 0;
}
