@import '../../../../style/variables.scss';

.delete-history-filters {
  background-color: white;
  padding: 20px 32px;
  display: flex;
  flex-direction: column;

  box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
  border-radius: 0 0 12px 12px;

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    & ~ * {
      margin-top: 16px;
    }
  }

  &__controls {
    margin-top: 20px;
  }

  &__full-width-control {
    width: 100%;
  }

  &__buttons-container {
    display: flex;
    gap: 16px;
    margin-left: auto;
  }

  .date-range,
  .MuiSelect-select {
    height: 40px;
  }

  .MuiSelect-icon {
    width: 12px;
    height: 6px;
    top: calc(50% - 3px);
    right: 14px;
  }

  .delete-history-filters__label {
    margin-bottom: 8px;
  }

  .delete-history-filters__input {
    &:last-of-type {
      margin-right: 0;
    }

    &--type-name {
      width: 25%;
    }
    &--type-lastName {
      width: 32%;
    }
    &--type-patronymic {
      width: calc(43% - 32px);
    }
  }

  .form-control.MuiFormControl-root {
    margin-top: 0;
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

// Стили для выпадающего списка
.MuiPaper-root {
  .admin-icon {
    width: 20px;
  }
}
