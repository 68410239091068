@import '../../../../style/variables.scss';

// TODO нужно переделать позиционирование стопок с выбранными элементами
// позиционировать относительно основной стопки элементов

.interests-directions {
  height: 100%;

  &__btn {
    width: 170px;
  }

  &__content {
    position: relative;
    padding: 20px 0 0 0;

    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 12px;
    text-align: center;
  }

  &__description,
  &__plug-description {
    font-weight: 400;
    line-height: 20px;
    color: $note-text;
    margin-bottom: 16px;
    text-align: center;
    max-width: 780px;
  }

  &__plug-description {
    max-width: 345px;
  }

  &__counter {
    font-weight: 700;
    color: $note-text;
  }

  &__btn-icon {
    path {
      fill: white;
    }
  }

  &__dnd-container {
    margin-top: 52px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__plug {
    position: relative;
    z-index: 0;
    max-width: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img, h5 {
      margin-bottom: 6px;
    }
  }

  &__items-container {
    position: absolute;
    z-index: 2;
    width: 340px;
    height: 248px;
    top: 156px;

    & > div {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .drag-item {
    z-index: 1;
    position: absolute;
    padding: 16px 20px;
    height: 248px;
    width: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 4px 4px 4px rgba(216, 216, 216, 0.12), 0px 6px 8px rgba(188, 188, 188, 0.12);
    border-radius: 12px;

    border: 1px solid $border-color;

    &__actions {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 12px;
    }

    &__btn {
      padding: 6px 16px;
      background-color: white;
    }

    &__btn-icon {
      margin-top: 2px;
      width: 13px;
      height: 11px;

      &--type-insipid {
        margin-right: 8px;
      }

      &--type-unknown {
        margin-left: 8px;
        transform: rotate(-90deg);
      }

      &--type-sipid {
        margin-left: 8px;
        transform: rotate(180deg);
      }
    }


  }

  .drop-container {
    position: absolute;
    height: 524px;
    width: 524px;
    overflow: hidden;
    border-radius: 50%;

    & > div {
      width: 100%;
      height: 100%;
    }

    .drag-item {
      width: 270px;
      height: 152px;

      &__title {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }

      &__actions {
        display: none;
      }

      img {
        width: 86px;
      }
    }


    &.insipid {
      left: -60px;
      top: 122px;
      background: #FFF0F0 url('../../../../images/interests/dragAndDrop/cross.png') no-repeat center;

      .drag-item {
        left: calc(50% - 135px);
        top: calc(50% - 82px);
        background-color: #FFC9C9;
        border: 1px solid #F2B4B4;
      }
    }

    &.sipid {
      right: -60px;
      top: 122px;
      background: #E8FBEB url('../../../../images/interests/dragAndDrop/heart.png') no-repeat center;

      .drag-item {
        right: calc(50% - 135px);
        top: calc(50% - 82px);
        background-color: #B2F2BB;
        border: 1px solid #9DE7A7;
      }
    }

    &.unknown {
      top: 484px;
      background: #F4F3F8 url('../../../../images/interests/dragAndDrop/unknown.png') no-repeat center;

      .drag-item {
        background-color: #D7D9E1;
        left: calc(50% - 135px);
        top: calc(50% - 82px);
      }
    }
  }

  &__drag-container {
    display: none;
    border: none;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    height: 60px;
    left: 0;
    right: 0;

    &::after {
      content: "";
      position: absolute;
      width: 134px;
      height: 5px;
      bottom: 9px;
      left: calc(50% - 67px);
      border-radius: 5px;
      background-color: #87879B;
    }
  }

}

@media (min-width: 1921px) {
  .interests-directions {
    .drop-container {
      &.insipid {
        left: auto;
        right: calc(50% + 200px + 262px);
      }
      &.sipid {
        right: auto;
        left: calc(50% + 200px + 262px);
      }
    }
  }
}


@media (max-width: 1600px) {
  .interests-directions {
    .drop-container {
      &.insipid {
        left: -200px;
      }
      &.sipid {
        right: -200px;
      }
    }
  }
}

@media (max-width: 1536px) {
  .interests-directions {
    .drop-container {
      &.unknown {
        top: 448px;
      }
    }
  }
}

@media (max-width: 1366px) {
  .interests-directions {
    .drop-container {
      &.sipid,
      &.insipid {
        top: 76px;
        background-image: none;
      }

      &.insipid {
        left: -160px;
      }

      &.sipid {
        right: -160px;
      }

      &.unknown {
        top: 452px;
        background-image: none;
      }
    }
  }
}


@media (max-width: 1280px) {
  .interests-directions {
    .drop-container {
      &.unknown {
        top: 438px;
      }
    }
  }
}

@media (max-width: 1022px) {
  .interests-directions {

    &__description {
      max-width: 317px;
    }

    &__content .drop-container {

      width: 524px;
      height: 524px;

      .drag-item {
        width: 256px;
        height: 256px;
      }

      &.sipid,
      &.insipid {
        top: 136px;

        .drag-item {
          left: calc(50% - 128px);
          top: calc(50% - 128px);
        }
      }

      &.unknown {
        top: 594px;
        .drag-item {
          left: calc(50% - 128px);
          top: calc(50% - 128px);
        }
      }

      &.insipid {
        left: -262px;
      }

      &.sipid {
        right: -262px;
      }
    }

    &__dnd-container {
      margin-top: 0;
    }

    & .interests-directions__items-container {
      top: 136px;
      width: 288px;
      height: 370px;
    }

    .drag-item {
      width: 288px;
      height: 370px;

      .MuiIconButton-root.drag-item__mobile-btn{
        border-radius: 50%;
        width: 48px;
        height: 48px;
        &--type-insipid {
          background-color: #FF8787;
        }
        &--type-sipid {
          background-color: #69DB7C;
        }
        &--type-unknown {
          background-color: #B0B3C3;
        }
      }
    }

    .initial-items-container {
      .drag-item {
        opacity: 0;
      }

      div.drag-item:nth-last-of-type(-n+4) {
        opacity: 1;
      }

      .drag-item--dragging {
        opacity: 1;
      }

      div.drag-item:not(.drag-item--dragging):nth-last-of-type(2) {
        transform: scale(0.94) translateY(20px) !important;
      }

      div.drag-item:not(.drag-item--dragging):nth-last-of-type(3) {
        transform: scale(0.88) translateY(42px) !important;
      }

      div.drag-item:not(.drag-item--dragging):nth-last-of-type(4) {
        transform: scale(0.82) translateY(68px) !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .interests-directions {

    &__items-container {
      position: static;
      margin-top: 16px;
    }

    &__plug {
      max-width: unset;
      &-info-btn {
        display: block;
        z-index: 2;
      }
    }
    &__description {
      max-width: 317px;
    }
    .drop-container {
      &.sipid,
      &.insipid {
        top: 148px;
      }

      &.insipid {
        left: -362px;
      }

      &.sipid {
        right: -362px;
      }
    }
  }
}

@media (max-width: 638px) {
  .interests-directions {
    &__title {
      display: none;
    }

    .drop-container {
      &.insipid {
        left: -280px;
      }

      &.sipid {
        right: -280px;
      }
    }
  }
}

@media (max-width: 542px) {
  .interests-directions {
    & .interests-directions__content .drop-container {
      &.insipid {
        left: -420px;
      }

      &.sipid {
        right: -420px;
      }

      &.unknown {
        top: unset;
        bottom: -420px;
      }
    }
  }
}

@media (max-width: 374px) {
  .interests-directions {
    position: relative;

    &__description {
      display: none;
    }

    &__btn {
      width: 100%;
    }

    &__items-container {
      margin-top: 8px;
    }

    .drop-container {
      &.insipid,
      &.sipid,
      &.unknown {
        display: none;
      }
    }

    .interests-directions__drag-container {
      display: block;
    }
  }
}


@media (max-width: 330px) {
  .interests-directions {
    .drag-item {
      width: 288px;
      height: 328px;
    }
    &__description {
      display: none;
    }
    .drop-container {
      display: none;
    }
  }

}

@media (max-width: 320px) {
  .scrollable-container {
    margin-bottom: 0;
  }
}
@media (max-height: 900px) {
  .interests-directions {
    &__items-container {
      top: 130px;
    }

    .drop-container {
      width: 410px;
      height: 410px;
      &.unknown {
        top: 420px;
      }
    }
  }
}

@media screen and (max-height: 900px) and (max-width: 1022px) {
  .interests-directions {
    .drop-container.unknown {
        top: unset;
        bottom: -262px;
    }
  }
}
