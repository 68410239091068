@import '../../../style/variables';

.award-container {
  width: 100%;
  margin-bottom: 20px;
  padding: 12px 32px 32px 32px;

  position: relative;

  .empty-data-plug {
    margin-left: 0;
    margin-top: 128px;
  }

  .title {
    margin-top: 20px;
  }

  .awards-title{
    font-size: 20px;
    line-height: 32px;
  }

  &__background {
    position: absolute;
    height: 160px;
    padding-left: 20px;
    padding-right: 16px;
    left: 12px;
    right: 12px;
    top: 12px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;

    img {
      position: absolute;
      right: 0;
      opacity: 0.8;
    }
  }

  .award-container__btn-add {
    margin-top: 12px;
  }

  .award-carousel {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 128px;

    .button-container {
      padding-top: 12px;
      padding-bottom: 16px;
    }
  }

  .year {
    position: absolute;
    left: 0;
    top: 20px;
    transform: rotate(270deg);
    color: $note-text;
    opacity: 0.4;
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.05em;
  }
}

.title-container{
  .award-container{
    &__cross-add{
      display: none;
    }
  }
}

@media (max-width:600px) {
  .title-container{
    .award-container{
      &__btn-add{
        display: none;
      }
      &__cross-add{
        background: none;
        padding: 0;
        border: 0;
        position: absolute;
        top: 21px;
        right: 18px;
        margin-right: 0px;
        display: flex !important;
        min-width: 40px;
        span{
          margin-right: 0;
          svg{
            height: 24px;
            width: 24px;
          }
        }
      }
      img{
        background-image: none;
      }
    }
    .award-carousel{
      margin-top: 92px;
    }
  }
  .award-container{
    &__background {
      height: 124px;
      img{
        display: none;
      }
    }
    .award-carousel{
      margin-top: 92px;
    }
  }
  .award-container{
    overflow: hidden;
    padding: 12px 0px 32px 32px;
  }
}



