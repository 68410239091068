@import '../../../../style/variables.scss';

.data-entity-container {
  &__filter-button {

    &.data-entity-container__filter-button--type-culture {
      background-color: $rose-lite;

      .MuiFormControlLabel-root .Mui-checked+.MuiFormControlLabel-label {
        background-color: $rose-strong;

        p {
          color: white;

          span {
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }
    }
  }

  .data-entity-container__btn-add {
    &--type-culture {
      background-color: $rose-lite;
      border-color: $rose-border;
      color: $rose-strong;

      &:hover,
      &:active {
        border: 2px solid $rose-strong;
        background-color: $rose-strong;
        color: white;
      }

      svg {
        path {
          stroke: $rose-strong;
        }
      }
    }
  }

}
