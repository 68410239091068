@import '../../../../../style/variables.scss';

.pdf-personal-diagnostic-list {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
  }

  &__items {
    position: relative;
    margin-top: 16px;
  }
}
