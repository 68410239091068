@import '../../../../../style/variables.scss';

.subject-details {
  height: 100%;

  display: flex;
  flex-direction: column;

  &__topics {
    flex-shrink: 1;
    overflow-y: auto;
    height: 100%;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.mobile-subject-details {
  height: 100%;

  &__header {
    position: relative;

    .button-close {
      position: absolute;
      top: 12px;
      right: 16px;

      width: 24px;
      height: 24px;
      padding: 0;
    }
  }

  &__content {
    height: 100%;
    padding-top: 20px;
  }
}
