@import '../../../../style/variables.scss';

.interests-summary {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $page-background;
  height: calc(100vh - 160px);

  &--webview {
    height: calc(100vh - 80px);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;
    overflow: auto;
    overflow: overlay;
    padding: 20px 64px 108px;
  }

  &__title {
    max-width: 838px;

    font-size: 20px;
    line-height: 32px;
    text-align: center;
  }

  &__description {
    max-width: 838px;
    margin-top: 4px;

    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $note-text;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    max-width: 1280px;
    margin-top: 32px;
  }

  &__add-btn {
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 0;
    margin: 0;
    border: none;
    background-color: white;
    width: 220px;
    height: 340px;
    border: 1px solid $border-color;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    color: $note-text;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .interests-summary__add-icon {
    width: 20px;
    margin-right: 2px;
    path {
      fill: $note-text;
      stroke: $note-text;
    }
  }
}

@media (max-width: 1280px) {
  .interests-summary {
    &__title {
      font-size: 18px;
      line-height: 28px;
    }
    &__content {
      padding-left: 48px;
      padding-right: 48px;
    }
  }
}

@media (max-width: 1023px) {
  .interests-summary {
    &__content {
      padding-right: 47px;
    }
  }
}

@media (max-width: 800px) {
  .interests-summary {
    &__content {
      padding-left: 38px;
      padding-right: 37px;
    }
  }
}

@media (max-width: 766px) {
  .interests-summary {

    &__cards {
      justify-content: center;
    }

    &__title,
    &__description {
      display: none;
    }

    &__content {
      padding-left: 30px;
      padding-right: 30px;
    }

    .interest-data-entity,
    .interests-summary__add-btn {
      height: 300px;
      width: 280px;
    }
  }
}

@media (max-width: 480px) {
  .interests-summary {

    &__content {
      padding-left: 0;
      padding-right: 0;
    }

    &__cards {
      gap: 12px;
    }
  }
}
