@import '../../../style/variables';

.pdf-gia-container {
  width: 1240px;
  margin-bottom: 24px;
  padding: 80px 32px 32px 32px;

  position: relative;

  &__background {
    position: absolute;
    height: 160px;
    padding: 6px 12px 12px;
    left: 12px;
    right: 12px;
    top: 12px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;

    img {
      position: absolute;
      right: 0;
      top: 8px;
    }
  }

  .title {
    padding-top: 20px;
    padding-left: 8px;
    
  }

  & * {
    outline: none;
  }
}
