@import './variables.scss';

@mixin font-face($size, $textSize, $family) {
  @font-face {
    font-family: $family;
    font-style: normal;
    font-weight: $size;

    src: local($family),
    url('../fonts/#{$family}/woff2/#{$family}-#{$textSize}.woff2') format('woff2'),
    url('../fonts/#{$family}/woff/#{$family}-#{$textSize}.woff') format('woff'),
    url('../fonts/#{$family}/ttf/#{$family}-#{$textSize}.ttf') format('truetype'),
  };

  @if $family == 'Lato' {
    @font-face {
      font-family: $family;
      font-style: italic;
      font-weight: $size;

      src: local($family),
      url('../fonts/#{$family}/woff2/#{$family}-#{$textSize}Italic.woff2') format('woff2'),
      url('../fonts/#{$family}/woff/#{$family}-#{$textSize}Italic.woff') format('woff'),
      url('../fonts/#{$family}/ttf/#{$family}-#{$textSize}Italic.ttf') format('truetype'),
    }
  }
};

@mixin breakpoint-up($size) {
  @if map-has-key($breakpoints, $size) {
    @media (min-width: map-get($breakpoints, $size)) {
      @content;
    }
  } @else {
    @media screen and (min-width:  $size) {
      @content;
    }
  }
}

@mixin breakpoint-down($size) {
  @if map-has-key($breakpoints, $size) {
    @media (max-width: map-get($breakpoints, $size)) {
      @content;
    }
  } @else {
    @media screen and (max-width: $size) {
      @content;
    }
  }
}

@mixin container($disableMaxWidth: false) {
  // max-width: var(--container-max-width);
  // margin-inline: auto;
  // padding-inline: 64px;

  // @if ($disableMaxWidth) {
  //   max-width: initial;
  // }

  // @media screen and (max-width: 1280px) {
  //   padding-inline: 32px;
  // }

  // @media screen and (max-width: 1024px) {
  //   padding-inline: 20px;
  // }

  // @media screen and (max-width: 639px) {
  //   padding-inline: 8px;
  // }
}

@mixin container-redesign() {
  max-width: var(--container-max-width);
  margin-inline: auto;
  // padding-inline: 64px;

  // @if ($disableMaxWidth) {
  //   max-width: initial;
  // }

  // @media screen and (max-width: 1280px) {
  //   padding-inline: 32px;
  // }

  // @media screen and (max-width: 1024px) {
  //   padding-inline: 20px;
  // }

  // @media screen and (max-width: 639px) {
  //   padding-inline: 8px;
  // }
}
