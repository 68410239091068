@import '../../../../style/variables.scss';

.select {
  &__item--common.MuiMenuItem-root {
    div .MuiTypography-root {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 640px;
      display: flex;
    }
  }
}

.search-select {
  &__placeholder {
    color: $disable-text;
  }

  &__item--last.MuiMenuItem-root {
    border-top: 1px solid $border-color;
  }
}
