@import '../../../../style/variables.scss';

.MuiPopover-paper {
  &.select-topped {
      margin-top: -12px;
  }
}

.render-value {
  display: flex;
  align-items: center;
  .MuiSvgIcon-root {
    margin-right: 8px;
    width: 20px;
  }
}

.select {
  width: 100%;
  .MuiChip-root {
    max-width: 330px;
  }

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
  }

  &__btn.MuiIconButton-root {
    padding: 0;
    margin-right: 14px;
  }

  &__btn--small.MuiIconButton-root {
    img {
      width: 16px;
      height: 16px;
    }

  }

  &--multiple {
    .MuiSelect-select {
      padding: 1px 2px;

    }
  }

  .select__checkbox {
    margin-right: 8px;
  }

  &__item-value {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__item--common.MuiMenuItem-root {
    border-radius: 4px;
    margin-bottom: 4px;
    padding: 0 12px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 560px;
    display: flex;
    &.Mui-selected {
      background-color: $blue-primrose;
      color: white;
      &:hover {
        color: $main-text;
      }
    }
  }

  &__item--marked.MuiMenuItem-root.MuiMenuItem-root {
    margin-bottom: 16px;
    position: relative;
    overflow: visible;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $border-color;
      bottom: -12px;
    }
  }

  &__item--common--type-study.MuiMenuItem-root {
    &.Mui-selected {
      background-color: $violet-strong;
      &:hover {
        color: $main-text;
      }
    }
  }

  &__item--multiple {
    &.Mui-selected.MuiListItem-root {
      background-color: white;
    }
  }

  &__item--divided.MuiMenuItem-root {
    border-top: 1px solid $border-color;
  }

  &__divider {
    width: calc(100% - 16px);
    height: 1px;
    background-color: $border-color;
    margin-left: 8px;
  }

  &__search-input {
    width: calc(100% - 16px);
    margin-left: 8px;
  }

  &__chips-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
  }

  &__counter {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $note-text;
    color: white;
    padding: 6px 12px;
    border-radius: 8px;
    margin-left: auto;
    margin-right: 12px;
  }

  &__dots {
    margin-left: 8px;
    margin-right: 8px;
  }

  &__placeholder {
    display: block;
    padding: 8px 16px;
    color: $disable-text;
  }
}

@media (max-width: 600px) {
  .select {
    .MuiChip-root {
      max-width: 80%;
    }
  }
}
