@import '../../../style/variables.scss';

.performance-tiles {
  display: grid;
  grid-template-columns: 384px 1fr;

  height: 618px;
  max-height: 618px;

  border-radius: 12px;
  border: 1px solid $border-color;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: white;

  &__results-container,
  &__details-container {
    height: 618px;
  }

  &__results-container {
    padding: 16px 16px 0 16px;
    border-right: 1px solid $border-color;

    display: flex;
    flex-direction: column;
  }

  &__subject-results {
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
    overflow-y: auto;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    & > *:last-child {
      padding-bottom: 20px;
    }
  }
}

@media screen and (max-width: 766px) {
  .performance-tiles {
    grid-template-columns: unset;
    border: transparent;
    box-shadow: unset;
    margin: 0;
    margin-bottom: 12px;
    height: unset;
    max-height: unset;

    &__details-container {
      display: none;
    }

    &__subject-results {
      & > *:last-child {
        margin-top: 4px;
        padding-bottom: 0px;
      }
    }

    &__results-container {
      height: unset;
      padding: 0;
      background-color: unset;
      border-right: unset;
    }
  }
}
