@import '../../../../style/variables.scss';

.interest-edit-form {
  position: relative;
  height: 100%;

  padding: 24px;

  &__background {
    z-index: -1;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 120px;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;

    // для правильной работы сокращения названия карточки
    display: flex;
  }

  &__interest-name {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__close-button.MuiButtonBase-root {
    position: absolute;
    top: 20px;
    right: 20px;

    padding: 0;
  }

  &__sections {
    display: flex;
    flex-direction: column;
    gap: 20px;

    margin-top: 16px;

    overflow-y: auto;
    height: calc(100% - 88px - 16px);
  }

  .checkbox-list-control {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;

    background-color: #ffffff;
    border-radius: 12px;
    border: 1px solid $border-color;

    &__title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    .MuiFormLabel-root {
      color: $main-text;
    }

    .MuiFormControlLabel-root {
      width: max-content;
    }
  }
}

@media (max-width: 766px) {
  .interest-edit-form {
    &__title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }
}
