@import '../../../../../../style/variables.scss';

.class-recommendation {
  padding: 20px;

  &__tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__avatar-container {
    margin-bottom: 16px;
  }

  &__avatar {
    width: 108px;
    height: auto;
  }
}
