.import-doughnut {
  position: relative;
  height: 24px;
  width: 24px;

  canvas {
    position: relative;
  }
}

