.clearable-select {
  .clearable-select__arrow {
    width: 12px;
    height: 6px;
  }
  .MuiAutocomplete-clearIndicator {
    margin-right: 10px;
  }
  .clearable-select__clear {
    width: 10px;
    height: 10px;
  }
  .MuiAutocomplete-endAdornment {
    padding: 4px 16px;
  }
}
