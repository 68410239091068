@import '../../../../../style/variables.scss';
@import '../../../../../style//mixins.scss';

.error-report-type {
  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;

    padding: 0;
    margin: 0;
    margin-top: 16px;
    list-style: none;

    li .MuiButton-root {
      width: 100%;
    }
  }
}

@include breakpoint-down(commonSm) {
  .error-report-type {
    &__list {
      margin-top: 12px;
    }
  }
}
