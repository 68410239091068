@import '../../../style/variables.scss';

.context-error-plug {
  padding: 0 16px;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    margin-top: 24px;
    text-align: center;
  }

  &__description {
    margin-top: 8px;
    color: $note-text;
    font-weight: 700;
    text-align: center;
  }

  &__button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: $blue-primrose;
    padding: 0;
    margin: 0;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 1366px) {
  .context-error-plug {
    &__image {
      width: 572px;
      height: 352px;
    }
  }
}

@media (max-width: 600px) {
  .context-error-plug {
    &__image {
      order: 1;
      width: 100%;
      height: auto;
      margin-top: auto;
    }
    &__title {
      margin-top: 10vh;
    }
  }
}
