@import '../../../../../../style/variables.scss';

.recommendation-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 220px;
  height: 341px;
  min-height: 341px;

  background-color: white;
  border: 1px solid $border-color;
  border-radius: 12px;

  overflow: hidden;

  &__illustration-container {
    position: absolute;
    bottom: 0;

    display: flex;
    align-items: flex-end;
  }

  &__illustration {
    position: absolute;
    bottom: 0;
  }

  &__wave.MuiSvgIcon-root {
    position: relative;
    width: inherit;
    height: 88px;
  }

  &__content {
    z-index: 1;
    width: 100%;
  }

  &__footer {
    z-index: 10;
    width: 100%;
  }
}
