@import '../../../../style/variables.scss';

.interests-entry {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  &__content {
    padding: 0 24px;
    width: 100%;
    height: 100%;
    background: white url('../../../../images/interests/entry/entryCoverDesktop.png') no-repeat 50% 100%;
    background-size: contain;
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }

  &__info {
    max-width: 860px;
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 24px;
  }

  &__description {
    font-size: 20px;
    line-height: 32px;
    color: $note-text;
    margin-bottom: 53px;
  }

  &__btn {
    width: 126px;
  }

  &__btn-icon {
    path {
      fill: white;
    }
  }

}

@media (max-width: 1920px) {
  .interests-entry {
    &__title {
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 16px;
    }

    &__description {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 24px;
    }

    &__info {
      max-width: 675px;
    }
  }
}

@media (max-width: 1280px) {
  .interests-entry {
    &__title {
      font-size: 28px;
      line-height: 42px;
    }
  }
}

@media (max-width: 1023px) {
  .interests-entry {
    &__content {
      background-image: url('../../../../images/interests/entry/entryCoverTablet.png');
    }
  }
}


@media (max-width: 767px) {
  .interests-entry {
    &__title {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 8px;
    }

    &__description {
      font-size: 16px;
      line-height: 26px;
    }
  }
}

@media (max-width: 638px) {
  .interests-entry {
    &__content {
      padding: 0;
      background-size: auto;
    }

    &__title {
      font-size: 18px;
      line-height: 24px;
    }

    &__info {
      padding: 32px 16px;
      padding-bottom: 16px;
    }

    &__description {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0;
    }
    .interests-entry__btn {
      margin-top: auto;
      width: 100%;
      border: 2px solid $blue_02;
    }
  }
}

@media (max-width: 375px) {
  .interests-entry {
    &__content {
      background-image: url('../../../../images/interests/entry/entryCoverMobile.png');
      background-size: contain;
    }
  }
}

@media (max-width: 320px) {
  .interests-entry {
    &__info {
      padding-top: 24px;
    }
  }
}

@media (max-height: 748px) {
  .interests-entry {
    &__info {
      padding: 32px 16px;
    }
  }
}

@media (max-height: 568px ) and (max-width: 375px)  {
  .interests-entry {
    &__content {
      background-image: url('../../../../images/interests/entry/entryCoverSmallHeight.png');
    }
  }
}
