@import '../../../style/variables.scss';

.bar-chart {
  height: 390px;
  width: 100%;
  margin-bottom: 40px;
  position: relative;

  &__title {
    text-align: center;
    color: $note-text;

    font-size: 16px;
    line-height: 24px;
  }

  &__skeleton {
    margin-top: 26px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-labels {
      padding-top: 18px;
      max-width: 133px;
      margin-right: 18px;
      display:  flex;
      flex-direction: column;
    }
    &-chart {
      flex-grow: 1;
      height: 292px;
      width: 100%;
    }
  }
  &__container {
    margin-top: 30px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }
  &__labels {
    height: 90%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  &__label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  &__canvas {
    flex-grow: 1;
    width: 80%;
  }
}
