@import '../../../../../style/variables.scss';
@import '../../../../../style/mixins.scss';

.general-info {
  &__title {
    margin-bottom: 18px;
    font-size: 18px;
    line-height: 28px;
  }

  &__general-info {
    margin: 0;
    padding: 0;
  }
}

@include breakpoint-down(commonSm) {
  .general-info {
    &__title {
      display: none;
    }
  }
}
