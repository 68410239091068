@import '../../../../../style/variables.scss';

.subject-details-empty {
  padding-top: 136px;
  height: 100%;

  background:
    url('../../../../../images/studyResults/wizardMeshik.png') no-repeat bottom center,
    url('../../../../../images/studyResults/backgroundWave.png') no-repeat bottom center;

  border-bottom-right-radius: 12px;

  &__description {
    max-width: 333px;
    margin: 0 auto;

    text-align: center;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    color: $note-text;
  }
}
