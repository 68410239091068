.thanks-dialog {
  // CommonDialog
  .dialog__title h6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    svg {
      width: 48px;
      height: 48px;
    }
  }
}
