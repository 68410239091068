@import '../../../style/variables.scss';

.change-settings-container {
  .loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search-alert {
    color: $note-text;
  }
}
