.information-error {
  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  &__body {
    margin-top: 8px;

    font-size: 16px;
    line-height: 24px;
  }

  &__entity-name {
    font-weight: 700;
  }
}
