@import '../../../style/variables.scss';

.pdf-data-entity-container {
  position: relative;
  padding-bottom: 8px;
  padding-top: 24px;
  background-color: white;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__title {
    font-size: 20px;
    line-height: 32px;
  }

  &__header {
    background-color: $pdf-background;
    border-top: 1px solid $border-color-pdf;
    border-bottom: 1px solid $border-color-pdf;
    padding: 20px 48px;
    height: 72px;
    display: flex;
    margin-bottom: 24px;
  }

  &__content {
    padding: 0 48px;
  }
}
