@import '../../../../style/variables.scss';

.mobile-input.MuiFormControl-root.MuiTextField-root {
  height: 48px;
  .MuiInput-formControl {
    margin-top: 0;
    height: 48px;
  }

  label {
    transform: translate(12px, 16px) scale(1);
    color: $note-text;
    background-color: white;



    &.Mui-required {
      &:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        top: 0;
        right: -12px;
        background-color: $red-rose_l2;
        background: url("../../../../icons/RequiredFieldMarker.svg")
        }
    }
    &.MuiInputLabel-shrink {
      z-index: 2;
      padding: 0 4px;
      transform: translate(12px, -7px) scale(0.75);
      &:after {
        top: 0;
        right: -10px;
        }
    }
  }
  .MuiInput-input {
    padding: 12px 16px;
  }
  .MuiInput-underline {
    border: 1px solid $control-stroke;
    border-radius: 8px;

    &.Mui-focused {
      border-color: #3f51b5;
    }

    &::before,
    &::after {
      display: none;
    }
  }
}
