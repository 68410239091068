.interests-navigation-button {
  --dot-gap: 24px;
  --border-width: 2px;

  width: 20px;
  height: 20px;
  min-width: 20px;
  padding: 0;
  border: var(--border-width) solid currentColor;
  border-radius: 50%;
  margin-right: var(--dot-gap);
  position: relative;

  display: flex;
  align-items: center;

  cursor: initial;

  &::after {
    content: "";
    position: absolute;
    right: calc((#{var(--dot-gap)} + #{var(--border-width)}) * -1);
    width: var(--dot-gap);
    height: 2px;
    background-color: currentColor;
  }

  &:last-of-type {
    margin-right: 0;
    &::after {
      display: none;
    }
  }

  &--visited {
    cursor: pointer;
  }
}

@media (max-width: 1365px) {
  .interests-navigation-button {
    width: 16px;
    height: 16px;
    min-width: 16px;
  }
}

@media (max-width: 638px) {
  .interests-navigation-button {
    --dot-gap: 32px;

    width: 10px;
    height: 10px;
    min-width: 10px;

    &::after {
      height: 1px;
    }
  }
}
