@import '../src/style/variables.scss';
@import '../src/style/mixins.scss';

:root {
  --container-max-width: 1280px;
  --global-header-height: 40px;
  // --MH-Max-Width: calc(var(--container-max-width));
  // переопределение css переменной из глобального хедера
  // максимальная ширина задается нашим контейнером
  --MH-Max-Width: 100%;
}

// Lato
@include font-face(100, 'Thin', 'Lato');
@include font-face(300, 'Light', 'Lato');
@include font-face(400, 'Regular', 'Lato');
@include font-face(500, 'Medium', 'Lato');
@include font-face(700, 'Bold', 'Lato');
@include font-face(900, 'Black', 'Lato');

// Inter
// Шрифты для пдф файла портфолио учащегося
@include font-face(100, 'Thin', 'Inter');
@include font-face(200, 'ExtraLight', 'Inter');
@include font-face(300, 'Light', 'Inter');
@include font-face(400, 'Regular', 'Inter');
@include font-face(500, 'Medium', 'Inter');
@include font-face(600, 'SemiBold', 'Inter');
@include font-face(700, 'Bold', 'Inter');
@include font-face(800, 'ExtraBold', 'Inter');
@include font-face(900, 'Black', 'Inter');


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: $scroll-disable;

  &:active, &:hover {
    background-color: $disable-text;
  }
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  font-family: 'Lato', sans-serif;

  scrollbar-width: 8px;
  scrollbar-color: $scroll-disable transparent;
  scrollbar-gutter: stable;
  overflow-x: hidden;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track:vertical {
    border-inline: 1px solid;
    border-color: $control-stroke;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
}

h1 {
  margin: 0;

  color: $main-text;

  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
}

h2 {
  margin: 0;

  color: $main-text;

  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
}

h3 {
  margin: 0;

  color: $main-text;

  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

h4 {
  margin: 0;

  color: $main-text;

  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

h5 {
  margin: 0;

  color: $main-text;

  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

p {
  margin: 0;

  color: $main-text;

  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
}

p.small {
  font-size: 14px;
  line-height: 20px;
}

p.tiny {
  font-size: 12px;
  line-height: 16px;
}

.small-text {
  color: $main-text;

  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.wee-text {
  color: $main-text;

  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
}

.box-shadow_empire {
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
}

.box-shadow_taipei {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
}

.box-shadow_tokyo {
  box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
}

.box-shadow_khalifa {
  box-shadow: 0 8px 12px rgba(0, 0, 0, .1);
}


#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.visually-hidden {
  // делает элемент невидимым но позволяет получить его ширину
  position: fixed !important;
  transform: scale(0);
}

.block-container {
  border-radius: 8px;
  background: $white;
}

.substrate {
  .substrate__icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;

    &--natural {
      width: unset;
      height: unset;
    }
  }

  .substrate__button {
    text-transform: capitalize;
    color: $border-color;
    border-radius: 8px;
    width: 75%;
    max-width: 324px;
  }

  .substrate__button:first-child:not(:only-child) {
    margin-bottom: 12px;
    margin-top: auto;

    &.substrate__button--setting {
      margin-top: 22%;
    }
  }

  .substrate__button--bottom {
    margin-top: auto;
    margin-bottom: 12px;
    background-color: transparent;
    color: $border-color;

    &:hover,
    &:focus,
    &:active,
    &:disabled {
      background-color: transparent;
      color: $border-color;
    }
  }

  .substrate__button--delete {
    background-color: transparent;
    color: $red-rose_l4;
    margin-top: auto;
    margin-bottom: 3%;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      color: $red-rose_l4;
    }
  }
}

@media (max-width: 1919px) {
  h1 {
    font-size: 28px;
    line-height: 42px;
  }

  h2 {
    font-size: 24px;
    line-height: 36px;
  }

  h3 {
    font-size: 20px;
    line-height: 32px;
  }

  h4 {
    font-size: 18px;
    line-height: 28px;
  }

  h5 {
    font-size: 16px;
    line-height: 24px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }

  p.small {
    font-size: 12px;
    line-height: 16px;
  }

  p.tiny {
    font-size: 10px;
    line-height: 14px;
  }
}

@media screen and (max-width: 1280px) {
  .block-container:not(.block-container--pdf) {
    border-radius: 16px;
  }
}
