@import '../../../style/variables.scss';

.polar-area-chart {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 263px;
  height: 263px;

  &__average {
    position: absolute;
    width: 66px;
    height: 66px;

    background-color: white;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    color: $main-text;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }
}

@media screen and (max-width: 766px) {
  .polar-area-chart {
    width: 246px;
    height: 246px;
  }
}
