.drawer {
  height: 100vh;
  z-index: 1000;
  .MuiBackdrop-root {
    height: 100%;
  }
  .MuiDrawer-paper {
    border-radius: 8px 0px 0px 8px;
    max-width: 724px;

    width: 100%;
    .drawer-child {
      height: 100vh;
      width: 100vw;
    }
  }
  &:not(.drawer--scrollable) {
    overflow-y: hidden;
    .MuiDrawer-paper {
      overflow-y: hidden;
    }
  }
  &--fullHeight {
    .MuiDrawer-paper {
      height: 100%;
    }
  }
}



@media (max-width: 600px) {
  .drawer {
    height: -webkit-fill-available;

    &:not(.drawer--scrollable) {
      touch-action: none;
      .MuiDrawer-paper {
        touch-action: none;
      }
    }
    &--fullHeight {
      .MuiDrawer-paper {
        height: -webkit-fill-available;
        touch-action: none;
      }
    }
    .MuiDrawer-paper {
      border-radius: 16px 16px 0px 0px;
    }
  }
}
