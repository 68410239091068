@import '../../../style/variables.scss';

.button-more {
  & &__btn {
    height: unset;
    padding: 0;

    color: $blue-primrose;
    background-color: $white;

    &:hover, &:active {
      color: $blue-primrose;
      background-color: $white;
      box-shadow: none;
    }
  }

  & &__arrow--flipped {
    margin-top: 3px;
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 766px) {
  .button-more {
    .MuiButton-label {
      font-size: 14px;
    }
  }
}

