@import '../../../style/variables.scss';

.pdf-portfolio-title-page {
    width: 1240px;
    height: 1754px;

    .title-page {
        height: 1753px;
    }

    .student-info {
        width: 990px;
        height: 220px;
        position: absolute;
        top: 909px;
        padding-left: 258px;
        font-family: 'Inter';
        font-weight: bold;
        background-color: transparent;
        align-items: start;

        &__fio {
            color: $main-text;
            font-size: 48px;
            line-height: 58px;
            padding-top: 58px;
        }

        &__school {
            color: $note-text-pdf;
            font-size: 24px;
            line-height: 29px;
            padding-top: 16px;
            padding-left: 2px;
        }
    }
}
