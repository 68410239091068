@import '../../style/variables.scss';

textarea {
  font-family: inherit;
  &:focus {
    border-color: $blue-primrose;
  }
  &::placeholder {
    color: $disable-text;
  }
}
