@import '../../../../../style/variables.scss';

.subject-themes {
  height: 100%;

  display: flex;
  flex-direction: column;

  &__topics {
    flex-shrink: 1;
    overflow-y: auto;
    height: 100%;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
