@import '../../../style/variables.scss';

.pdf-page-template {
  width: 100%;
  font-size: 16px;
  line-height: 24px;

  .pdf-info {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 0;
    padding: 0 48px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 2px solid $border-color-pdf;

    &__creation-date {
      color: $note-text-pdf;
      margin-bottom: 28px;
    }
  }

  .pdf-paging {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    padding: 0 48px;
    border-top: 2px solid $control-stroke;

    color: $main-text;

    &__user-info,
    &__current-page {
      margin-bottom: 32px;
    }

    &__user-fio {
      font-weight: 700;
    }
  }
}
