@import '../../../../../style/variables.scss';

.gia-data-entity {
  position: relative;
  width: 224px;
  height: 270px;
  padding: 20px 16px 24px 16px;

  text-align: center;

  background-color: white;
  border: 1px solid $border-color;
  border-radius: 12px;

  .btn-more {
    position: absolute;
    right: 8px;
    top: 4px;
    padding: 8px;
  }

  .progeress-bar {
    width: 80px;
    height: 80px;
    margin-bottom: 12px;
  }

  .date-result {
    color: $note-text;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
  }

  .result {
    display: inline;

    color: $note-text;
    font-size: 16px;
    line-height: 24px;
  }

  .title {
    width: 190px;
    margin-top: 4px;

    display: -webkit-box;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    height: 48px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    overflow: hidden;
  }
}

@media (max-width: 600px) {
  .gia-data-entity {

    p {
      font-size: 14px;
      line-height: 20px;
    }

    .title {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

@media (max-width: 320px) {
  .gia-data-entity {

    p {
      font-size: 14px;
      line-height: 20px;
    }

    .title {
      font-size: 16px;
      line-height: 24px;
    }

    .progeress-bar {
      position: relative;
      width: 70px;
      height: 70px;
      margin-bottom: 12px;
    }
  }
}
