@import '../../../../../style/variables.scss';

.step-footer {
  .interests-footer {
    position: unset;
  }

  z-index: 4;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #ffffff;

  &__progress {
    display: none;

    padding: 16px;
    padding-bottom: 0;
  }
}

@media (max-width: 638px) {
  .step-footer {
    border-top: 1px solid $border-color;

    &__progress {
      display: block;
    }

    .interests-footer {
      border: unset;
    }
  }
}
