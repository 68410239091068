@import '../../../../../style/variables.scss';

.file-drop {
  z-index: 2;

  border: 2px dashed #D6D6DF;
  border-radius: 8px;

  &__input {
    display: none;
    pointer-events: none;
  }
}
