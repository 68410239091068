@import '../../../../../../style/variables.scss';

.section-recommendation {
  padding: 20px;

  &__place {
    margin-top: 18px;
    color: $main-text;

    font-size: 14px;
    line-height: 18px;
  }
}
