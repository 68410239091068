@import '../../../style/variables.scss';
@import '../../../style/mixins.scss';

.interests-page {
  display: flex;
  flex-direction: column;
  background-color: white;

  &--scrollable {
    overflow: initial;
  }

  .interests-page__header {
    z-index: 3;

    height: 72px;
    width: 100%;
    background-color: white;
    border-bottom: 2px solid $border-color;
    display: flex;
    align-items: center;

    transition: background-color 0.2s;
  }

  .interests-page__header-content {
    @include container;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .interests-page__header-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }

  .interests-page__button {
    margin-right: 16px;
    padding: 0;
  }

  .interests-page__icon {
    fill: $note-text;
  }
}

@media (max-width: 1365px) {
  .interests-page {
    .interests-page__header-title {
      font-size: 18px;
      line-height: 28px;
    }
  }
}

@media (max-width: 1023px) {
  .interests-page {
    .interests-page__header {
      height: 56px;
    }

    .interests-page__button {
      width: 32px;
      height: 32px;
    }
  }
}

@media (max-width: 638px) {
  .interests-page {
    .interests-page__header {
      position: sticky;
      top: 0;
    }
  }
}

@media (max-width: 600px) {
  .interests-page {
    margin-bottom: 0;
  }
}

@media (max-width: 375px) {
  .interests-page {
    .interests-page__header-title {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
