.student-portfolio-pdf {
  width: 1240px;
  height: 1754px;
  position: relative;
  z-index: -1;

  &__container {
    padding: 102px 0 100px 0;

    font-size: 20px;
    line-height: 32px;
  }
}
