@import '../../../../../style/variables.scss';

.subject-topics-empty {
  padding-top: 136px;
  height: 100%;

  background:
    url('../../../../../images/studyResults/noData.png') no-repeat center bottom 130px,
    url('../../../../../images/studyResults/backgroundWave.png') no-repeat center bottom;

  border-bottom-right-radius: 12px;

  &__description {
    max-width: 333px;
    margin: 0 auto;

    text-align: center;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    color: $note-text;
  }
}

.subject-topics-empty--mobile {
  display: flex;
  align-items: center;
  min-height: 100%;

  padding-top: 0;
  background: none;
}

.subject-topics-empty--mobile .subject-topics-empty {
  &__description {

    height: 300px;
    background: url('../../../../../images/studyResults/noData.png') no-repeat center top 80px;

    font-size: 16px;
  }
}
