@import '../../../style/variables.scss';

.file {
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 11px;
  min-height: 72px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap !important;

  & ~ * {
    margin-top: 8px !important;
  }

  &__name {
    color: $main-text;
  }
  &__size {
    color: $note-text;
  }

  &__image {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    flex: 0 0 auto;
    background-color: $page-background;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__info {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    word-break: break-all;
  }

  &__controls {
    margin-left: auto;
    display: flex;
  }

  &__button-icon {
    path {
      fill: $note-text;
    }
  }

  &__button-close,
  &__button-download{
    width: 48px;
    height: 48px;
  }

  &__button-download svg path {
    fill: $note-text;
  }
}

@media (max-width: 600px) {
  .file {
    &__info {
      width: 66%;
    }
    &__button-close.MuiIconButton-root {
      padding: 6px;
    }
    .file__button-download {
      display: none;
    }
    &.MuiGrid-item:last-of-type {
      margin-bottom: 82px;
    }
  }
}

@media (max-width: 400px) {
  .file {
    &__info {
      width: 60%;
    }
  }
}
