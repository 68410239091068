@import '../../../../../style/variables.scss';

.interests-kinds--desktop {
  overflow: auto;
  display: flex;
  align-items: center;
  gap: 8px;

  .interests-kinds__kind {
    display: flex;
    align-items: center;
    gap: 8px;

    cursor: pointer;
    font-family: Lato;
    border: none;
    font-size: 16px;
    line-height: 24px;
    color: $note-text;
    background-color: $page-background;
    border-radius: 8px;
    padding: 4px 16px;
    white-space: nowrap;

    &--selected {
      background-color: $blue-primrose;
      color: white;
    }
  }

  .interests-kinds__more-popper {
    margin-top: 40px;
  }

  .interests-kinds__more {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding: 16px 24px;

    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid $border-color;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
  }

  .interests-kinds__button-more {
    .dots {
      display: flex;
      gap: 2.5px;

      & > * {
        width: 3.5px;
        height: 3.5px;

        background-color: $note-text;
        border-radius: 50%;
      }
    }
  }

  .interests-kinds__icon-selected.MuiSvgIcon-root {
    width: 20px;
    height: 20px;

    path {
      fill: #ffffff;
    }
  }
}

.interests-kinds--mobile {
  .MuiPaper-root {
    padding: 32px 24px;
    border-radius: 16px 16px 0 0;
  }

  .interests-kinds__items {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .MuiFormControlLabel-label p {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .interests-kinds__buttons {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 16px;
  }
}

@media (max-width: 375px) {
  .interests-kinds--mobile {
    .interests-kinds__button {
      flex-grow: 1 !important;
    }
  }
}
