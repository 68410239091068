@import '../../../style/variables.scss';

.mobile-drag-container {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 36px;

  &__handle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    width: 64px;
    height: 4px;
    margin: 0 auto;
    margin-top: 16px;

    border-radius: 100px;
    background-color: $disable-text;
  }

  &__button {
    width: 100%;
    height: 100%;

    border: none;
    outline: none;
    background: transparent;
  }
}
