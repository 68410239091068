@import '../../../../style/variables.scss';

@mixin container-width($margins) {
  width: calc(100% - #{$margins} * 2);
  margin-left: auto;
  margin-right: auto;
}

.interests-bubbles {
  --bubbles-margins: 64px;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  &__content {
    position: relative;
    width: 100%;
    min-height: 689px;
    background-color: white;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__bubble-container {
    margin-top: 12px;
    @include container-width(var(--bubbles-margins));
    padding: 0 20px 80px 20px;

    overflow-x: hidden;
  }

  &__bubble-wrapper {
    width: 224px;
    height: 224px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 64px;

    &--selected {
      .interests-bubbles__bubble {
        width: 200px;
        height: 200px;

        border: none;
        font-weight: 500;
      }
    }
  }

  &__bubble {
    width: 176px;
    height: 176px;
    border: none;
    position: relative;
    z-index: 2;
  }

  .interests-bubbles__child.bubble {
    z-index: 3;
    width: 116px;
    height: 116px;
    border: 2px solid $control-stroke;
    background-color: white;
    border-radius: 50%;
    position: absolute;

    & .bubble__child-info {
      font-size: 12px;
      line-height: 16px;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__pointer {
    position: absolute;
    height: 1px;
    background-color: $control-stroke;
    transform-origin: left;

    left: 50%;
  }

  .bubble {
    font-family: Lato;
    cursor: pointer;
    transition: 0.5s;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    border: 1px solid $border-color;
    background-color: $page-background;
    color: $main-text;
    font-size: 16px;
    line-height: 24px;

    &__actions-container {
      position: absolute;
      top: 4px;
      left: 16px;
      display: flex;
      align-items: center;
      border-radius: 50%;
      padding: 4px;
    }

    &__child-opener {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border: 1px solid $border-color;
      position: absolute;
      top: 4px;
      right: 16px;
      display: flex;
      align-items: center;
      border-radius: 50%;
      padding: 4px;
      background: white url('../../../../icons/interests/actions/plus.svg') no-repeat center;

      &--open {
        background-image: url('../../../../icons/interests/actions/minus.svg');
      }
    }

    &__parent-info {
      pointer-events: none;
    }
  }
}

@media (max-width: 1365px) {
  .interests-bubbles {
    --bubbles-margins: 32px;

    .bubble {
      width: 156px;
      height: 156px;

      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media (max-width: 1022px) {
  .interests-bubbles {
    --bubbles-margins: 0px;
  }
}

@media (max-width: 638px) {
  .interests-bubbles {

    &__bubble-wrapper {
      width: 170px;
      height: 170px;
      margin-bottom: 16px;
    }

    .bubble {
      width: 140px;
      height: 140px;

      &__parent-info {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@media (max-width: 413px) {
  .interests-bubbles {
    &__bubble-container {
      padding-inline: 0;
      padding-bottom: 110px;
    }

    &__bubble-wrapper {
      width: 140px;
      height: 140px;
    }
  }
}
