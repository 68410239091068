@import '../../../style/variables.scss';

.linked-object {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid $border-color;
  background-color: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 16px 0;

  & ~ * {
    margin-top: 12px;
  }

  &__indicator {
    width: 58px;
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 12px 0 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__info {
    width: 80%;
    margin-left: 72px;
  }

  &__name {
    font-weight: 700;
  }

  &__open-btn.MuiIconButton-root  {
    margin-left: auto;
    .MuiSvgIcon-root {
      fill: $note-text;
      path {
        fill: $note-text;
      }
    }
  }

  &__features-container {
    width: 100%;
    overflow-x: auto;
    display: flex;
    align-items: center;
    margin-top: 12px;
    text-transform: lowercase;
  }

  &__feature {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    height: 32px;
    background-color: $page-background;
    color: $note-text;
    white-space: nowrap;
    padding: 6px 12px;
    border-radius: 12px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;

    &--approve {
      margin-right: 0;
      padding: 0;
      background-color: #E8FBEB;
      color: #51CF66;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .icon {
    &--type-sport {
      fill: #3BC9DB
    }
    &--type-study {
      fill: #9775FA
    }
    &--type-science {
      fill: #748FFC
    }
    &--type-creation {
      fill: #FFA94D
    }
    &--type-civil {
      fill: #69DB7C
    }
    &--type-culture {
      fill: #FF8787
    }
  }
}

@media (max-width: 600px) {
  .linked-object {
    .linked-object__info {
      width: 60%;
    }
  }
}
