@import '../../../../style/variables.scss';

.pdf-reward-block {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 268px;
  height: 300px;

  border: 2px solid $border-color-pdf;
  text-align: center;

  &__other-info {
    position: relative;
    height: 80px;
    padding: 22px 12px 16px 12px;

    border-top: 1px solid $border-color-pdf;
  }

  &__verification {
    position: absolute;
    top: 12px;
    left: 12px;
  }

  .reward-name {
    width: 196px;
    height: 48px;

    color: $main-text;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  .reward-date {
    margin-top: 12px;

    color: $note-text-pdf;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .reward-type {
    z-index: 100;
    position: absolute;
    top: -15px;

    display: flex;
    align-items: center;
    height: 28px;
    padding: 0 12px;

    border: 1px solid $border-color-pdf;
    border-radius: 4px;
    background-color: $pdf-background;

    color: $note-text-pdf;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .reward-description {
    width: 248px;

    color: $main-text;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
}
