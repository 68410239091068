@import '../../../style/variables.scss';

.pdf-performance-block {
  position: relative;
  margin-bottom: 24px;
  padding-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;

    &::after {
      display: none;
    }
  }

  &::after {
    --pdf-horizontal-padding: 48px;
    content: "";

    position: absolute;
    bottom: 0;
    display: block;
    width: calc(100% + var(--pdf-horizontal-padding) * 2);
    height: 2px;
    background-color: $border-color-pdf;

    transform: translateX(-48px);
  }
}
