@import '../../../../style/variables.scss';

.form-control.mobile-select-control {
  z-index: 1;
  .form-input__label {
    font-size: 16px;
    line-height: 24px;
    transform: translate(12px, 12px) scale(1);
    color: $note-text;
    background-color: white;
    overflow: visible !important;

      &--required {
        &:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        top: 3px;
        right: -10px;
        background-color: $red-rose_l2;
        background: url("../../../../icons/RequiredFieldMarker.svg")
        }
    }

    &.MuiInputLabel-shrink {
      z-index: 2;
      padding: 0 4px;
      transform: translate(12px, -10px) scale(0.75);
    }
  }

  .mobile-select {
    height: 48px;

    .MuiOutlinedInput-root {
      border-radius: 8px;
    }

    .MuiSelect-outlined {
      padding: 12px 16px;
      background-color: transparent;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #D7D9E1;
      border-radius: 8px;

    }

    &.Mui-focused {

      .MuiOutlinedInput-notchedOutline {
        border-color: #3f51b5;
      }

    }

    .MuiSelect-icon {
      width: 20px;
      height: 20px;
      top: calc(50% - 10px);
    }
  }
}

