@import '../../../../style/variables.scss';

.pdf-diagnostic-card {
  display: flex;
  align-items: center;

  width: 100%;
  padding: 20px 24px;

  border: 1px solid $border-color-pdf;
  background-color: #ffffff;

  .info {
    flex-grow: 1;
    flex-shrink: 1;

    &__title {
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
    }

    &__subject,
    &__date {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }

    &__subject {
      margin-top: 8px;
    }

    &__date {
      margin-top: 16px;
    }
  }

  .result {
    flex-shrink: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    min-width: 240px;
    height: 100%;

    padding-left: 16px;

    &__title,
    &__data,
    &__level-type {
      font-size: 16px;
      line-height: 24px;
    }

    &__title, &__level-type {
      color: $note-text-pdf;
    }

    &__title {
      font-weight: 700;
    }

    &__data {
      margin-top: 8px;
    }

    &__chart {
      width: 100px;
      height: 100px;

      .exam-doughnut__result {
        font-size: 18px;
      }
    }
  }
}
