@import '../../../../../../style/variables.scss';

.subject-topics {
  height: 100%;
  // overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  table {
    display: block;
    width: 100%;
    white-space: nowrap;
  }

  th,
  td {
    padding: 0;
    padding-right: 20px;


    &:first-child {
      padding-left: 20px;
    }
  }

  th {
    height: 40px;
    // background-color: rgba($page-background, 0.5);
    border-bottom: 1px solid $border-color;

    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: $note-text;
    z-index: 2;
  }

  .MuiTable-stickyHeader {
    border-collapse: collapse;
  }

  .row {
    position: relative;
    border-bottom: 1px solid $border-color;

    &__arrow {
      position: absolute;
      right: 20px;
      width: 24px;
      height: 24px;

      opacity: 0;
      transform: translateY(50%);
      transition: opacity 0.2s;

      svg {
        width: inherit;
        height: inherit;

        fill: #CC5DE8;
      }
    }
  }

  // TODO вернуть когда будет доступен подробный просмотр тем
  /* .row:hover .row__arrow {
    opacity: 1;
  }

  .row:hover {
    background-color: #fbf7fd;
    cursor: pointer;
    transition: background-color 0.2s;

    .cell-topic {
      font-weight: 700;
    }

    .cell-lessons,
    .cell-result {
      opacity: 0;
      transition: opacity 0.2s;
    }
  } */

  .head-cell {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: $note-text;
  }

  .cell {
    height: 52px;
    border: none;

    font-size: 16px;
    line-height: 24px;

    color: $main-text;
  }

  .cell-topic {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cell-lessons {
    display: flex;
    align-items: center;

    font-weight: 500;

    &--grayed {
      color: $note-text;
    }

    &__chart {
      display: inline-block;
      margin-right: 6px;

      .exam-doughnut__header {
        display: none;
      }

      .exam-doughnut {
        width: 20px;
        height: 20px;
      }
    }
  }

  .cell-result {
    font-weight: 700;
  }

  .cell-expand {
    width: 100%;
    max-width: 1px;
  }
}
