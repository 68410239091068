.settings-switch {
  &.MuiSwitch-root {
    width: 40px;
    height: 24px;
  }

  & .MuiSwitch-thumb {
    width: 16px;
    height: 16px;
  }

  & .MuiSwitch-track {
    border: 1px solid #E0FCF3;
  }

  & .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(16px);

    & + .MuiSwitch-track {
      border: 1px solid #E0FCF3;
      background-color: #20C997;
    }
  }
}
