@import '../../../../style/variables.scss';

.select {
  &__item--common.MuiMenuItem-root {
    div .MuiTypography-root {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 640px;
      display: flex;
    }
  }

  &__subheader.MuiListSubheader-root {
    height: 32px;
    background-color: white;

    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    color: $note-text;

    pointer-events: none;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
