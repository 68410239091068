@import '../../../../../style/variables.scss';

.interest-data-entity {
  width: 220px;
  height: 340px;
  position: relative;


  .card {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.8s ease;
    backface-visibility: hidden;

    .front, .back {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      transition: 1s;
      backface-visibility: hidden;
      background-color: #fff;
      border: 1px solid $border-color;
      border-radius: 12px;
    }

    .front {
      background-repeat: no-repeat;
      background-size: cover;
      padding: 48px 12px 16px;
    }

    .back {
      transform: rotateY(-180deg);
      background-color: white;
      &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 24px 16px;
      }
    }

    .back__content {
      display: flex;
      flex-direction: column;
      height: 100%;

      background-color: white;
      border-radius: 12px 12px 10px 10px;

      overflow: hidden;
    }

    &:hover {
      .front {
        transform: rotateY(180deg);
      }
      .back {
        transform: rotateY(0deg);
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__name-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 188px;
    text-align: center;

    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: $main-text;

    &--back {
      color: white;
    }
  }

  &__actions-container {
    width: 100%;
    border-top: 1px solid $border-color;
    margin-top: auto;
    height: 56px;

    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &__spacer {
    width: 1px;
    height: 32px;
    background-color: $border-color;
  }

  &__icon {
    path {
      fill: $note-text;
    }
  }

  &__attributes-container {
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
  }

  &__attribute {
    padding: 6px 11px;
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  .action-icon {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__info-container-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    padding: 16px;
  }

  &__info-title {
    width: 100%;
    color: $note-text;

    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  &__info-text {
    width: 100%;
    margin-top: 4px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 14px;
    line-height: 20px;

    &--long {
      -webkit-line-clamp: 4;
    }
  }
}
