@import '../../../../../style/variables.scss';

@mixin container-width($margins) {
  width: calc(100% - #{$margins} * 2);
  margin-left: auto;
  margin-right: auto;
}

.interests-header {
  --content-margins: 64px;

  z-index: 4;
  width: 100%;
  // ограничение по ширине нужно для корректной работы функции useHiddenHorizontalList
  max-width: 100vw;

  top: 0;
  position: sticky;
  margin-top: 20px;

  background-color: #ffffff;

  &__background {
    @include container-width(var(--content-margins));
    height: 132px;

    padding: 16px;
    border-radius: 8px;

    will-change: height;
    transition: height 0.2s;
  }

  &__title {
    margin-top: 16px;
    text-align: center;

    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  &__description {
    margin-top: 8px;
    text-align: center;

    font-size: 16px;
    line-height: 24px;
    color: $note-text;
  }

  &__filters {
    display: flex;
    flex-direction: column;
    gap: 12px;

    margin: 12px 0;
    @include container-width(var(--content-margins));
  }

  &__filter-container {
    position: relative;

    .input {
      background-color: white;
      position: relative;
      z-index: 1;
      margin-top: 0;
      width: 100%;
    }

    svg path {
      fill: $disable-text
    }
  }

  // &__kinds-container {
  //   overflow: auto;
  //   display: flex;
  //   align-items: center;
  // }

  .MuiInput-root {
    height: 36px;

    .MuiInput-input::placeholder {
      font-size: 14px;
    }

    .MuiSvgIcon-root {
      height: 20px;
      width: 20px;
    }
  }
}

.interests-header--scrolled .interests-header {
  &__background {
    width: 100%;
    height: 72px !important;
  }

  &__title {
    margin-top: 0;
  }

  &__progress,
  &__description {
    display: none;
  }
}

@media (max-width: 1365px) {
  .interests-header {
    --content-margins: 32px;

    &__background {
      height: 120px;
    }

    &__title {
      font-size: 18px;
      line-height: 28px;
    }

    &__description {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media (max-width: 1022px) {
  .interests-header {
    --content-margins: 24px;
  }
}

@media (max-width: 766px) {
  .interests-header {
    margin-top: 16px;

    &__filters {
      flex-direction: row;
      gap: 16px;
    }

    &__filter-container {
      flex-grow: 1;
    }

    &__kinds-container {
      flex-shrink: 0;
    }

    &__kinds-button.MuiButton-root {
      font-size: 14px;
      line-height: 20px;
      color: $note-text;
      border: 1px solid $disable-text;

      &:hover {
        color: $note-text;
        border: 1px solid $disable-text;
        background: none;
      }

      .MuiSvgIcon-root {
        fill: none;
        stroke: $note-text;
        stroke-width: 2;

        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
  }
}

@media (max-width: 638px) {
  .interests-header {
    --content-margins: 16px;

    top: 56px;
    margin-top: 0;

    &__background {
      display: none;
    }
  }
}

@media (max-width: 430px) {
  .interests-header {
    &__kinds-button.MuiButton-root {
      padding: 8px !important;
      min-width: 36px;
      width: 36px;

      .MuiSvgIcon-root {
        margin: 0;
      }

      .MuiButton-label {
        width: max-content;
      }

      .label {
        display: none;
      }
    }
  }
}
