@import '../../../../../style/variables.scss';

.detail-checkbox {
  display: flex;
  flex-direction: column;
  padding: 16px;

  border: 1px solid $border-color;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

  textarea {
    &:not([aria-hidden="true"]) {
      overflow: auto;
      position: relative;
    }
  }
}
