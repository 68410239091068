@import '../../../style/variables.scss';

.common-carousel .carousel {
  position: relative;

  .carousel__slide-focus-ring {
    display: none;
  }

  // pure-react-carousel
  .carousel {
    // нужно для корректного отображения расстояния между карточками
    &__slider {
      display: flex;
    }
    &__slider-tray {
      width: unset !important;
    }
    &__slide {
      margin-right: 16px;
    }
  }

  .slider--pdf {
    width: 980px;
    .carousel__slider-tray.carousel__slider-tray--horizontal {
      width: 100% !important;
      flex-wrap: wrap;
    }
  }

  .arrow {
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 50%;
    padding: 0;
    bottom: 50%;
    width: 40px;
    height: 40px;

    &:disabled {
      display: none;
    }

    &_right {
      position: absolute;
      right: -12px;
    }

    &_left {
      position: absolute;
      left: -12px;
      & img {
        transform: rotate(180deg);
      }
    }
  }
}

@media (min-width: 1440px) {
  .common-carousel .carousel {
    width: 100%;
  }
}

@media (max-width: 638px) {
  .common-carousel .carousel {
    width: 100%;

    .carousel__slider {
      &--horizontal{
        overflow: hidden;
      }
      &--visible .slide{
        max-width: 250px;
      }
      // &-tray-wrapper{
      //   overflow: hidden;
      // }
    }
    .arrow{
      display: none;
    }

    .carousel__slider-tray-wrapper {
      display: inline-flex;
    }
    .carousel__slider-tray--horizontal{
      .carousel__slide--visible{
        max-width: 250px;
      }
    }
  }
}
