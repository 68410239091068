@import '../../../../style/variables.scss';

.pdf-exam-container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px 48px 32px;

  border-bottom: 2px solid $border-color-pdf;
  margin-left: -48px;
  margin-right: -48px;

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }

  &:first-of-type {
    padding-top: 0;
  }

  &__title {
    padding-bottom: 16px;
    line-height: 28px;
  }

  &__exams {
    margin: -8px;
  }
}
