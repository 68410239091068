@import '../../../../style/variables.scss';

.line-chart {
  &__axis-x {
    display: flex;
    justify-content: center;
    padding-top: 8px;
    color: $note-text;
  }
  &__axis-y {
    transform: rotate(-90deg);
    position: absolute;
    top: 125px;
    left: 0;
    color: $note-text;
  }
  .line-wrapper {
    height: 312px;
    width: 100%;
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .line-chart {
    .line-wrapper {
      width: unset;
    }
  }
}
