@import '../../../../style/variables.scss';

.entity-actions-container {
  padding: 20px 24px;

  &__title {
    padding-inline: 16px;
    color: $note-text;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
  }

  &__items {
    color: $main-text;
  }

  &.MuiPaper-rounded {
    border-radius: 8px;
  }
}
