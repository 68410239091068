@import '../../../../../style/variables.scss';

.files-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 16px;

  .file {
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    position: relative;
    width: 153px;
    height: 200px;
    display: flex;
    flex-direction: column;
    padding: 0;

    &__extension {
      color: white;
      opacity: 0.4;
      font-weight: 800;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.1em;
    }

    &__name {
      font-weight: 500;
      text-align: justify;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--type-image {
      .file__top-container {
        background-color: #DA77F2;
      }
    }
    &--type-pdf {
      .file__top-container {
        background-color: #FF8787;
      }
    }
    &--type-presentation {
      .file__top-container {
        background-color: #FFA94D;
      }
    }
    &--type-excel {
      .file__top-container {
        background-color: #69DB7C;
      }
    }
    &--type-document {
      .file__top-container {
        background-color: #4DABF7;
      }
    }

    &__top-container {
      height: 80px;
      padding: 12px;
      padding-top: 20px;
      border-radius: 12px 12px 0 0;
      display: flex;
      justify-content: center;
    }

    &__bottom-container {
      height: calc(100% - 80px);
      padding: 28px 12px;
    }

    .btn-download {
      position: absolute;
      top: 62px;
      left: 56px;
      width: 42px;
      height: 42px;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 80px;

      svg {
        path {
          fill: $note-text;
        }
      }

      .MuiSvgIcon-root {
        width: 18px;
      }
    }
  }
}
