@import '../../../../style/variables.scss';

.interests-checker {
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    position: relative;
    padding: 24px 0 82px 0;

    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 12px;
    text-align: center;
  }

  &__description {
    font-weight: 400;
    color: $note-text;
    margin-bottom: 16px;
    text-align: center;
    max-width: 882px;
  }

  &__dnd-container {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    gap: 12px;
  }

  .drop-container {
    width: 290px;
    padding: 12px;
    border-radius: 12px;
    border: 2px dashed $border-color;

    &__mobile-indicator {
      display: none;
    }

    &__title {
      font-size: 18px;
      text-align: center;
    }

    & > div {
      width: 100%;
      height: 100%;
    }

    &.insipid {
      .drag-item {
        background-color: #FFC9C9;
      }
    }

    &.sipid {
      .drag-item {
        background-color: #B2F2BB;
      }
    }

    &.unknown {
      .drag-item {
        background-color: #D7D9E1;
      }
    }
  }

  .drag-item {
    height: 164px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 2px 2px 12px rgba(63, 66, 71, 0.07), 0px 8px 16px rgba(63, 66, 71, 0.12);
    border-radius: 12px;
    margin-top: 12px;
    background-repeat: no-repeat;
    background-size: cover;

    &__content {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__title {
      text-align: center;
      font-size: 16px;
      line-height: 22px;
    }

    img {
      width: 102px;
      height: 102px;
    }
  }

  &__drag-container {
    display: none;
    border: none;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    height: 60px;
    left: 0;
    right: 0;

    &::after {
      content: "";
      position: absolute;
      width: 134px;
      height: 5px;
      bottom: 9px;
      left: calc(50% - 67px);
      border-radius: 5px;
      background-color: #87879B;
    }
  }

}

@media (max-width: 1023px) {
  .interests-checker {

    .drop-container {
      width: 284px;
    }

    .drag-item {
      height: 256px;
      justify-content: flex-start;
    }
  }
}

@media (max-width: 900px) {

  .interests-checker {

    &__content {
      padding-top: 0;
      padding-bottom: 100px;
    }

    &__title,
    &__description {
      display: none;
    }
    &__dnd-container {
      padding-top: 20px;
      grid-auto-flow: row;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    .drop-container {
      position: relative;
      border: none;
      width: auto;
      width: 334px;
      height: 274px;
      padding: 0;
      margin-top: 12px;

      &.insipid {
        padding-top: 0;
        margin-top: 0;
      }

      &__title {
        font-size: 16px;
        width: 100%;
      }

      .drop-container__mobile-indicator {
        height: auto;
        width: auto;
        position: absolute;
        bottom: 0;
        left: 32px;
        width: 262px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__ellipse {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $border-color;
        margin-left: 4px;
        &:first-of-type {
          margin-left: 0;
        }

        &--active {
          background-color: $note-text;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        z-index: 9999;
        width: 32px;
        height: 32px;
        background-color: white;
        border-radius: 100px;
        &::after {
          display: none;
        }
      }
      .swiper-button-next {
        right: -6px;
        background: url('../../../../icons/arrowRight.svg') no-repeat 50%;
      }
      .swiper-button-prev {
        left: -6px;
        background: url('../../../../icons/arrowLeft.svg') no-repeat 50%;
      }

      .swiper-pagination {
        bottom: 4px;
      }

      .swiper-pagination-bullet {
        opacity: 1;
        background-color: $border-color;
      }

      .swiper-pagination-bullet-active {
        background-color: $note-text;
      }
    }

    .drag-item {
      position: relative;
      top: 12px;
      left: 36px;
      width: 262px;
      height: 224px;
      margin-top: 0;
      padding: 0;

      &__content {
        padding-top: 12px;
        padding-left: 12px;
        padding-right: 12px;
      }

      &__controls {
        margin-top: auto;
        width: 100%;
        background-color: white;
        border-radius: 0 0 12px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
      }

      &__mobile-btn {
        border: 1px solid $control-stroke;
        border-radius: 8px;

        &--rotated {
          transform: rotate(180deg);
          margin-left: 20px;
        }
      }

      &__btn-icon {
        path {
          fill: $note-text;
        }
      }
    }
    &__drag-container {
      display: block;
    }
  }
}

@media (max-width: 350px) {
  .interests-checker {
    .drag-item {
      width: 232px;
      left: 0;
      margin-left: calc(50vw - 122px);
    }
    .drop-container {
      width: calc(100vw - 16px);
      .drop-container__mobile-indicator {
        width: 232px;
      }
    }
  }
}
