@import '../../../../style/variables.scss';
@import '../../../../style/mixins.scss';

.searcher {
  @include container;

  .multiple-search-select {
    padding: 0;
    width: 100%;
  }
}

.searcher-container {
  position: relative;
  min-width: 900px;
  padding: 32px;
  margin-top: 24px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 16px;
}
