@import '../../../../../style/variables.scss';

.mobile-info-dialog {
  .MuiPaper-root.MuiDrawer-paper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 24px;

    border-radius: 16px 16px 0 0;
  }

  &__icon svg {
    width: 48px;
    height: 48px;
  }

  &__text-wrapper {
    max-width: 234px;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
}
