@import '../../../../style/variables.scss';

.multiple-search-select {
  display: flex;
  flex-direction: column;
  width: 264px;
  padding: 8px 12px;

  .MuiSelect-select.MuiSelect-select {
    padding-right: 28px;
  }

  &__placeholder {
    color: $disable-text;
  }

  &__input {
    width: calc(100% - 16px);
    margin: 12px 8px 0px 8px;
    .MuiSvgIcon-root {
      width: 15px !important;
    }
  }

  &__checkboxes {
    &--unchecked {
      display: flex;
      flex-direction: column;
      padding: 16px 24px 0px 24px;
      .MuiFormControlLabel-root {
        padding-bottom: 20px;
        margin: 0px;
        .MuiTypography-root {
          padding-left: 12px;
        }
      }
    }

    &--checked {
      display: flex;
      flex-direction: column;
      padding: 16px 24px 0px 24px;
      margin-bottom: 4px;
      border-bottom: 1px solid #E8E8EF;
      .MuiFormControlLabel-root {
        padding-bottom: 20px;
        margin: 0px;
        .MuiTypography-root {
          padding-left: 12px;
        }
      }
    }
  }

  &_no-results {
    display: flex;
    padding: 20px 24px 20px 24px;
  }

  .MuiInputBase-root {
    .MuiSvgIcon-root {
      width: 15px;
      height: 15px;
      padding-top: 8px;
      margin-right: 16px;
    }
  }
  .MuiSelect-iconOpen {
    position: absolute;
    top: calc(50% - 4px);
  }
}
