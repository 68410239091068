@import '../../../style/variables.scss';

.pdf-portfolio-personal-info {
    display: flex;
    height: 128px;
    width: 1240px;
    padding: 24px 48px;
    border-bottom: 2px solid $border-color-pdf;

    .pdf-student-avatar {
        height: 80px;
        width: 80px;
        font-size: 24px;
        line-height: 36px;
    }

    .pdf-student-info {
        padding-left: 16px;
        padding-top: 8px;

        &__fio {
            color: $main-text;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
        }

        &__school {
            color: $note-text-pdf;
            padding-top: 4px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
    }
}
