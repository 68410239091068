@import '../../../../style/variables.scss';

.pdf-exam-block {
  width: 210px;
  min-height: 270px;
  padding: 20px 16px 24px 16px;
  margin: 8px;

  text-align: center;

  background-color: white;
  border: 2px solid $border-color-pdf;

  .progeress-bar {
    position: relative;
    width: 80px;
    height: 80px;
    margin-bottom: 12px;
  }

  .progress-image {
    position: absolute;
    top: 4px;
    left: -6px;
    z-index: 3;
  }

  .subject-category {
    display: inline-block;
    margin-bottom: 28px;

    line-height: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $note-text;
  }
  .subject-result {
    color: $note-text;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
  }

  .result {
    display: inline;

    color: $note-text;
    font-size: 16px;
    line-height: 24px;

    &--empty {
      display: block;
      height: 24px;
    }
  }

  .title {
    $title-line-height: 24px;

    width: 190px;
    margin-top: 4px;
    margin-bottom: 2px;

    font-weight: 700;
    font-size: 18px;
    line-height: $title-line-height;
  }
}
