@import '../../../../../../../style/variables.scss';

.subject-topics-mobile-wrapper {
  padding: 0 20px;
}

.subject-topics-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  font-size: 14px;
  gap: 12px;

  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
  }

  &__lesson {
    display: flex;
    align-items: center;
  }

  &__data {
    display: flex;
    align-items: center;
    padding-top: 8px;
    gap: 12px;
    > div {
      display: flex;
    }
    .exam-doughnut {
      width: 20px;
      height: 20px;
    }
  }

  &__lesson-chart {
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 6px;
    &--grayed {
      color: $note-text;
    }
  }

  &__lesson-result {
    font-weight: 700;
    padding-left: 8px;
  }

  &__lesson-significative {
    color: #7B819B;
    font-weight: 500;
  }
}
