@import '../../../style/variables.scss';

.form-control.MuiFormControl-root {
  margin-top: 20px;
  width: 100%;

  &:first-of-type {
    margin-top: 0;
  }


  .form-input__double-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    & :first-child {
      margin-right: 12px;
    }

    .MuiTypography-root {
      margin-right: 0;
    }

    .MuiInput-input {
      min-width: 22px
    }
  }

  .form-input__error {
    margin-top: 8px;
    padding: 8px 16px;
    color: $red-rose;
    background-color: $red-rose_l2;
  }

  .MuiInputLabel-asterisk {
    display: none;
  }
  .form-input__label {
    white-space: nowrap;
    color: $main-text;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;

    &.Mui-focused {
      color: #3f51b5;
    }

    &--required {
      &:after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      top: 0;
      right: -12px;
      background-color: $red-rose_l2;
      background: url("../../../icons/RequiredFieldMarker.svg")
      }
  }

  }
    label + .MuiInput-formControl,
    .date-picker-input,
    .date-range-picker,
    .MuiAutocomplete-root {
      margin-top: 28px;
      width: 100%;
    }

    label + .date-picker-input {
      margin-top: 28px;
      width: 220px;
    }

    label.Mui-required {
      &:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        top: 3px;
        right: -10px;
        background-color: $red-rose_l2;
        background: url("../../../icons/RequiredFieldMarker.svg")
        }
    }

    &.form-control--disabled {
      pointer-events: none;

      .MuiInputBase-root {
        border-radius: 8px;
        .MuiSelect-root {
          background-color: $page-background;
          color: $note-text;
          pointer-events: none;
        }
      }

      .MuiInputBase-root {
        background-color: $page-background;
        color: $note-text;
        pointer-events: none;
      }

      .MuiSelect-select.MuiInputBase-input {
        color: rgb(176, 179, 195);
      }

      .date-range-picker__inputs {
        background-color: $page-background;
        color: $note-text;

        .input {
          background-color: $page-background;
          color: $note-text;
        }
      }
    }
}

@media (max-width: 600px) {
  .form-control.MuiFormControl-root.MuiFormControl-root.MuiFormControl-root {
    margin-top: 14px;

    .form-input__label {
      color: $note-text;
      max-width: 80%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .form-input__double-container {
      margin-top: 32px;
      justify-content: flex-start;

      .mobile-input {
        max-width: 100px;
      }

      .mobile-input:first-of-type {

        margin-right: 12px;
      }

      .form-input__label {
        width: 100%;
        overflow: visible;
        color: $note-text;
      }
    }

    &.form-control--disabled {
      pointer-events: none;

      .MuiInputLabel-formControl, .date-range-picker__mobile-label{
        z-index: 2;
        background-color: $page-background;
      }
      .form-input__label {
        background-color: transparent;
      }
    }

    .date-picker-input {
      margin-top: 0;

      .MuiInputBase-root {
        border-radius: 8px;
        height: 48px;
        margin-top: 0;
      }

      .MuiInput-input {
        padding-left: 12px;
      }

      .MuiInputLabel-formControl {
        font-size: 16px;
        line-height: 24px;
        transform: translate(12px, 12px) scale(1);
        color: $note-text;
        &.MuiInputLabel-shrink {
          z-index: 2;
          padding: 0 4px;
          background-color: white;
          transform: translate(12px, -10px) scale(0.75);
        }
      }
    }
  }
}
