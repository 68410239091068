@import '../../../../../style/variables.scss';

.feature-preview-carousel .carousel {
  position: relative;

  &__slide-focus-ring {
    display: none;
  }

  &__dot-group {
    display: flex;
    justify-content: center;
    gap: 8px;

    padding-bottom: 24px;

    .carousel__dot {
      width: 8px;
      height: 8px;
      padding: 0;

      border-radius: 50%;
      background-color: $control-stroke;
      border: none;
    }

    .carousel__dot--selected {
      background-color: $blue-primrose;
    }
  }

  .arrow {
    position: absolute;
    bottom: 96px;

    width: 48px;
    height: 48px;
    padding: 0;

    border-radius: 50%;
    background-color: $white;
    border: 1px solid $border-color;

    .MuiSvgIcon-root {
      width: 32px;
      height: 32px;
      color: $main-text;
    }

    &:disabled .MuiSvgIcon-root {
      color: $disable-text;
    }
  }

  .arrow_right {
    right: 24px;
  }

  .arrow_left {
    left: 24px;
  }
}

@media (max-width: 600px) {
  .feature-preview-carousel .carousel {
    .arrow {
      display: none;
    }
  }
}
