@import '../../../style/variables.scss';

.verification-sign {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  border-radius: 12px;
  background-color: #E8FBEB;

  &--pdf {
    border-radius: 4px;
    border: 1px solid $border-color-pdf;
    background-color: $pdf-background;
  }
}
