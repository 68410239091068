@import '../../../style/variables.scss';

.doughnut {
  position: relative;
  height: 100%;
  width: 100%;

  canvas {
    position: relative;
    z-index: 1;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    position: absolute;
    top: calc( 50% - 30px);
    left: calc( 50% - 30px);
  }

  &__label {
    color: $note-text;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
  
  &__result {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }
}

@media (max-width: 615px) {
  .doughnut {
    &__header {
      &--no-mobile {
        display: none;
      }
    }
  }
}

