@import '../../../../style/variables.scss';

.feature-modal {
  position: relative;


  &__close {
    z-index: 10;
    position: absolute;
    top: 20px;
    right: 24px;

    cursor: pointer;
  }

  .MuiDialog-paperWidthSm {
    max-width: 900px;
    width: 100%;
    overflow: hidden;
  }
}
