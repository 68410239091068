@import '../../../style/variables.scss';
@import '../../../style/mixins.scss';

.funnel-block {
  padding: 20px;

  border: 1px solid $border-color;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

@include breakpoint-down(commonSm) {
  .funnel-block {
    padding: 8px 16px;
  }
}
