@import '../../../style/variables.scss';

.setting-block {
  border-radius: 8px;
  border: 1px solid #E8E8EF;
  background-color: white;
  position: relative;

  & ~ & {
    margin-top: 20px;
  }

  .setting-block__arrow {
    position: absolute;
    left: 30px;
    padding: 0;
    transform: rotate(180deg);
    &--reversed {
      transform: rotate(0deg);
    }
  }

  .setting-block__arrow-icon {
    width: 13px;
    height: 7px;
    path {
      fill: currentColor;
    }
  }

  &__opener {
    padding-left: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__section {
    background-color: white;
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: center;
    padding: 16px 44px 16px 44px;
    &--rolled {
      border-radius: 8px;
    }
  }

  &__child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 26px 12px 44px;
    border-top: 1px solid $border-color;

    .setting-block__status-container {
      width: 140px;
      margin-left: 0;
    }

    .setting-block__opener {
      margin-left: 8px;
    }

    &--type-secondary {
      padding-left: 96px;
    }
  }

  &__right-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 460px;
  }

  &__left-column {
    display: flex;
    align-items: center;
  }

  &__child-icon {
    margin-right: 12px;
    path {
      fill: $disable-text;
    }
  }

  &__status-container {
    width: 140px;
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .setting-block__switch {
    margin-right: 12px;
  }

  &__control.MuiFormControl-root {
    width: 280px;
    &:first-of-type {
      margin-top: 0;
    }
    .MuiInput-formControl {
      margin-top: 0;
  }
  }

  &__control--disabled {
    pointer-events: none;
    .MuiInputBase-root {
      .MuiSelect-root {

        background-color: $page-background;
        color: $note-text;
        pointer-events: none;
      }
    }
    .MuiInputBase-root {
      border-radius: 8px;
      background-color: $page-background;
      color: $note-text;
      pointer-events: none;
    }
  }

  .MuiSelect-icon {
    width: 14px;
    margin-right: 14px;
  }

  .message {
    z-index: 9999999;
    width: 455px;
    position: relative;
    margin-bottom: 20px;
    filter: drop-shadow(0px 4px 12px rgba(146, 146, 146, 0.2));

    &__btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .MuiPaper-root {
      padding: 20px 24px;
      border-radius: 12px;
    }

    &__title {
      margin-bottom: 12px;
    }

    .message__button {
      margin-top: 12px;
      margin-left: 20px;
    }

    .message__btn-close {
      position: absolute;
      top: 6px;
      right: 0;
    }
    &__arrow,
    &__arrow::before {
      position: absolute;
      width: 24px;
      height: 24px;
      background: inherit;
    }
    &__arrow {
      visibility: hidden;
      left: 24px
    }
    &__arrow::before {
      background-color: white;
      visibility: visible;
      content: '';
      transform: rotate(45deg);
    }

    &[x-placement^='top'] {
      margin-left: -12px;
    }

    &[x-placement^='top'] > div .message__arrow {
      top: auto;
      bottom: -4px;

    }

    &[x-placement^='bottom'] {
      margin-top: 20px;
      margin-right: 72px;
    }

    &[x-placement^='bottom'] > div .message__arrow {
      top: -4px;
      left: auto;
      right: 24px;
    }

  }

  .message__title {
    font-size: 14px;
  }
  
}
