@import '../../../style/variables.scss';

.portfolio-item {
  &__container {
    padding: 20px;
    background-color: white;
    border-radius: 8px;

    &--has-linked {
      border-radius: 8px 8px 0px 0px;
    }
  }
  &__top-container {
    display: flex;
    gap: 8px;
    max-width: 100%;
  }

  &__icon.MuiSvgIcon-root {
    width: 16px;
    margin-right: 6px;
  }

  &__content {
    margin-top: 12px;
  }

  &__date {
    margin-top: 8px;
    color: $note-text;
  }

  &__name {
    width: 100%;
    height: 28px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__popper {
    z-index: 99999;

    .MuiPopover-paper {
      margin: 0;
    }
  }

  .linked-item {
    display: flex;
    padding: 8px 20px;
    background-color: white;
    border-top: 1px solid $border-color;
    border-radius: 0px 0px 8px 8px;

    &__content {
      width: 90%;
    }

    &__image {
      margin-right: 12px;
    }

    &__type {
      color: $note-text;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__name {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
