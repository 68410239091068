.thanks-teacher {
  position: relative;
  width: 100%;

  border-radius: 8px;
  text-align: center;

  &--mobile {
    .thanks-teacher {
      &__img {
        width: 100%;
        max-height: 96px;
      }

      &__description {
        padding: 16px;
      }

      &__text {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__header {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }

  &__img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
  }

  &__description {
    padding: 24px 24px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  &__text {
    max-width: 656px;
    font-size: 16px;
    line-height: 24px;
  }
}
