@import '../../../../../style/variables.scss';

.info-dialog {
  .MuiPaper-root.MuiDialog-paper {
    max-width: 464px;
    padding: 24px 20px;

    background: #ffffff;
    border-radius: 8px;
    border: 1px solid $border-color;
  }

  &__title-container {
    display: flex;
    gap: 8px;
  }

  &__icon {
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__title {
    margin-right: auto;

    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: $main-text;
  }

  &__close-button.MuiIconButton-root {
    width: 20px;
    height: 20px;
    padding: 0;

    svg {
      color: $note-text;
      width: 20px;
      height: 20px;
    }
  }

  &__description {
    margin-top: 8px;
    padding-left: 28px;
    padding-right: 20px;

    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $main-text;
  }
}
