@import '../../../../style/variables.scss';

.interests-footer {
  z-index: 2;
  padding: 16px 64px;
  background-color: white;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $border-color;

  &__btn-icon {
    path {
      fill: white;
    }
  }

  &__btn-icon--reversed {
    transform: rotate(180deg);
    path {
      fill: #757575;
    }
  }

  &__button.MuiButtonBase-root.MuiButton-root {
    padding: 12px 24px;

    &:hover,
    &:active {
      .interests-footer__btn-icon--reversed {
        path {
          fill: white;
        }
      }
    }
  }

  .interests-footer__button-close {
    margin-right: 16px;
  }
}

@media (max-width: 1365px) {
  .interests-footer {
    &__button.MuiButtonBase-root.MuiButton-root {
      height: 40px;
    }
  }
}

@media (max-width: 1280px) {
  .interests-footer {
    padding: 16px 32px;
  }
}

@media (max-width: 767px) {
  .interests-footer {
    padding: 16px 24px;
  }
}

@media (max-width: 414px) {
  .interests-footer {
    padding: 16px;

    gap: 16px;

    &__button {
      flex-grow: 1;
    }
  }
}
