@import '../../../style/variables.scss';

.subsection-visibility {
  position: sticky;

  display: flex;
  flex-direction: column;

  &--scrolled {
    // 170 высота хедера при скролле
    top: 170px + 20px;
  }

  .MuiList-root {
    padding: 0;
  }

  .MuiListItem-root {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 8px;

    width: 100%;
    height: 52px;

    margin-bottom: 8px;
    padding: 12px 20px;
    background-color: #ffffff;
    border-radius: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .MuiListItemText-root {
    margin: 0;

    .MuiTypography-root {
      color: $main-text;
      font-weight: 500;
      font-size: 17px;
      line-height: 28px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
