// adaptive
$commonBreakpointSm: 639px;
$commonBreakpointMd: 767px;
$commonBreakpointLg: 1023px;

$breakpoints: (
  'commonSm': $commonBreakpointSm,
  'commonMd': $commonBreakpointMd,
  'commonLg': $commonBreakpointLg
);

// color
$heading: #0c0d10;
$main-text: #181920;
$note-text: #7b819b;
$note-text-pdf: #585858;
$disable-text: #b0b3c3;
$control-stroke: #d7d9e1;
$delimiters: #e5e6eb;
$page-background: #f4f3f8;
$pdf-background: #f0f0f0;
$white: #fff;
$dark-background: #25314f;
$border-color: #e8e8ef;
$border-color-pdf: #b9b9b9;
$navigation-select: #f4f9ff;
$substrate: rgba(55, 59, 84, 0.8);
$shadow: rgba(0, 0, 0, 0.6);
$table-header: #f9f9fb;
$gray_divider_dot: #C4C4C4;
$notification-success: #6fc86d;
$tooltip: rgba(43, 56, 88, 0.8);
$scroll-disable:rgba( 176,179,195,0.6);


// blue-primrose
$blue-primrose_d8: #2b3858;
$blue-primrose_d6: #0044a2;
$blue-primrose: #0055cb;
$blue-primrose_l4: #4794ff;
$blue-primrose_l2: #e0edff;

// amethyst-starling
$amethyst-starling_d8: #3d114e;
$amethyst-starling_d6: #621b7c;
$amethyst-starling: #7a229b;
$amethyst-starling_l4: #bb61dc;
$amethyst-starling_l2: #f4e5f9;

// red-rose
$red-rose_d8: #66151a;
$red-rose_d6: #a3212a;
$red-rose: #cc2a35;
$red-rose_l4: #e47b83;
$red-rose_l2: #fbe9ea;

// orange-sunset
$orange-sunset_d8: #99540c;
$orange-sunset_d6: #ed861a;
$orange-sunset: #f2a657;
$orange-sunset_l4: #f7ca9a;
$orange-sunset_l2: #fef6ee;

// fir-green
$fir-green_d8: #1f581d;
$fir-green_d6: #318c2e;
$fir-green: #3daf3a;
$fir-green_l4: #84d682;
$fir-green_l2: #ebf8ea;

// sunglow
$sunglow_d8: #d39f00;
$sunglow_d6: #f1b600;
$sunglow: #ffcc2e;
$sunglow_l4: #ffebad;
$sunglow_l2: #fffae9;

// munsell-blue
$munsell-blue_d8: #006f77;
$munsell-blue_d6: #00949e;
$munsell-blue: #00b9c6;
$munsell-blue_l4: #b5f2f5;
$munsell-blue_l2: #e6fbfd;

//violet
$violet-lite: #F8F7FF;
$violet-medium: #F1ECFF;
$violet-strong: #845EF7;
$violet-border: #E5DBFF;

//indigo
$indigo-lite: #F5F8FF;
$indigo-medium: #ECF1FF;
$indigo-strong: #5C7CFA;
$indigo-border: #BAC8FF;

//cyan
$cyan-lite: #F0FCFD;
$cyan-medium: #E1FAFC;
$cyan-strong: #22B8CF;
$cyan-bright: #15AABF;
$cyan-border: #99E9F2;

//orange
$orange-lite: #FFF9F2;
$orange-medium: #FFF9F2;
$orange-strong: #FF922B;
$orange-bright: #F76707;
$orange-border: #FFD8A8;

//rose
$rose-lite: #F0FCFD;
$rose-medium: #FFF9F2;
$rose-strong: #C92A2A;
$rose-border: #FFD8A8;

//green
$civil-lite: #F4FCF6;
$civil-medium: #E8FBEB;
$civil-strong: #2B8A3E;
$civil-border: #B2F2BB;

// blue
$blue_01: #F2F9FF;
$blue_02: #E7F4FF;
$blue_03: #D0EBFF;
$blue_05: #74C0FC;
$blue_07: #339AF0;
$blue_09: #1C7ED6;
$blue_11: #1864AB;
