.avatar {
  display: flex;
  text-transform: uppercase;
  font-weight: 900;
  color: white;
  .MuiAvatar-root {
    margin: 0;
    font-size: 12px;
  }

  .small {
    width: 40;
    height: 40;
  }

  .medium {
    width: 44;
    height: 44;
  }

  .large {
    width: 112;
    height: 112;
  }


  &--large {
    .MuiAvatar-root {
      font-size: 34px;
    }
  }
}
