@import '../../../../style/variables.scss';

.student-toast-container {
  &.Toastify__toast-container {
    width: 464px;
    min-height: 68px;
  }

  .Toastify__toast {
    border-radius: 8px;
    font-family: 'Lato';
    font-weight: 700;
    line-height: 20px;
    padding: 10px 20px 10px 48px;
    position: relative;

    &:after {
      position: absolute;
      content: url('../../../../icons/notificationStar.svg');
      top: 22px;
      left: 20px;
      width: 20px;
      height: 20px;
    }

    &--success {
      background-color: $notification-success;

      &:after {
        content: url('../../../../icons/notificationSuccess.svg');
      }
    }
    &--error {
      background-color: $red-rose_l4;

      &:after {
        content: url('../../../../icons/notificationError.svg');
      }
    }
    &--warning {
      background-color: $tooltip;

      &:after {
        content: url('../../../../icons/notificationStar.svg');
      }
    }
    &--default {
      background-color: $blue-primrose_l4;

      &:after {
        content: url('../../../../icons/notificationInfo.svg');
      }
    }
  }

  .Toastify__close-button {
    align-self: center;
    opacity: 1;
  }

  .Toastify__close-button > svg {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 460px) {
    &.Toastify__toast-container {
      width: 100%;
    }
  }
}

