@import '../../../style/variables.scss';

.portfolio-navigation {
  position: sticky;
  width: 100%;
  background-color: transparent;
  font-weight: 500;
  line-height: 24px;
  transition: all 0.35s;

  &--scrolled {
    top: 180px;
    max-width: 313px;
  }

  &__inner.MuiGrid-container {
    min-height: 100px;
    flex-wrap: nowrap;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .navigation-badge {
    padding: 2px 12px 4px;
    background: linear-gradient(84.77deg, #5587FF -31.45%, #A064FF 107.08%);
    color: white;
    border-radius: 16px;
  }
}

@media screen and (max-width: 1511px) {
  .portfolio-navigation {
    &--scrolled {
      top: 84px;
    }
  }
}
