@import '../../../../../style/variables.scss';

.performance-chart {
  background-color: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid $border-color;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  max-height: 442px;

  .line {
    //width: 100%;
    height: inherit;
  }

  &__left-column {
    width: 65%;
    display: flex;
    flex-direction: column;
  }
  &__right-column {
    width:  35%;
    padding-left: 24px;
    margin-bottom: 12px;
  }

  &__subjects-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__subjects-container {
    overflow-y: auto;
    padding-right: 12px;
    max-height: calc(100% - 28px);
    max-width: 100%;
  }

  &__clear-button {
    display: block;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    font-weight: 700;
    color: $note-text;
    padding: 0;
  }

  .performance-chart__filter {
    width: max-content;
    margin-bottom: 16px;
    .MuiFormControlLabel-root {
      margin-left: 4px;
    }
    .MuiFormControlLabel-label.MuiTypography-body1.MuiTypography-root {
      margin-left: 0;
      padding: 4px 16px;
    }
  }

  &__checkbox-container {
    height: 40px;
    display: none;
    position: relative;
    font-size: 16px;
    line-height: 24px;


    &::after {
      content: "";
      width: 12px;
      height: 6px;
      background: url('../../../../../icons/iconSelectArrow.svg') no-repeat;
      position: absolute;
      top: 16px;
      right: 18px;
    }
    &--active {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &__checkbox-menu {
    padding: 12px 8px;
    max-height: 440px;
    overflow-y: auto;
  }

  &__prompt {
    align-self: center;
    color: $note-text;
    font-weight: 400;
    margin-left: 40px;
    font-size: 14px;
    line-height: 20px;
  }

  &__prompt--rotated {
    transform: rotate(-90deg);
    position: absolute;
    left: 32px;
    margin-left: 0;
    margin-bottom: 20px;
  }

  &__subject {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &:first-of-type {
      margin-bottom: 16px;
    }
  }

  &__subject-name {
    color: $note-text;
    max-width: 245px;
    overflow: hidden;
    margin-left: 12px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__subject--checked {
    .performance-chart__subject-name {
      font-weight: 700;
    }
  }

  &__filter-select {
    border: 1px solid $control-stroke;
    border-radius: 8px;

    .MuiInputBase-root {
      width: 100%;
    }
    .MuiSelect-select {
      height: 40px;
      display: flex;
      align-items: center;
      border: none;
    }
    .MuiSelect-icon {
      width: 12px;
      height: 6px;
      top: calc(50% - 3px);
      right: 14px;
    }
    .MuiSelect-root[aria-expanded="true"] {
      border-color: $violet-border;
    }
  }
}

@media (max-width: 800px) {
  .performance-chart {
    padding: 20px;
    position: relative;

    .line-chart {
      display: flex;
      align-items: center;

      overflow-x: scroll;
      &::-webkit-scrollbar {
        height: 0;
      }
      &__axis-x {
        padding: 0;
      }
      canvas {
        width: inherit !important;
      }
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 16px;
    }
    .performance-chart__filter {
      margin-bottom: 0;
    }

    &__filter-select {
      width: 100%;
    }

    &__checkbox-container {
      margin-left: auto;
      display: flex;
      align-items: center;
      width: 100%;
      border: 1px solid $control-stroke;
      border-radius: 8px;
      padding: 8px 16px;

      margin-top: 10px;
      margin-left: 0;
    }
    .performance-chart__select-arrow {
      margin-left: auto;
      width: 12px;
    }
    .performance-chart__select-arrow--open {
      transform: rotate(180deg);
    }
    &__right-column {
      display: none;
    }
    &__left-column {
      width: 100%;
      max-width: 600px;
    }
  }
}

@media screen and (max-width: 767px) {
  .performance-chart {
    &__prompt-label {
      display: none;
    }
  }
}

@media (max-width: 700px) {
  .performance-chart {
    max-height: 552px;
  }
}
