.hidden-entity-container {
  position: relative;
  width: auto;
  height: auto;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__switch {
    z-index: 11;
    position: absolute;
    top: 36px;
    right: 32px;
  }

  &__overlay {
    z-index: 10;
    position: absolute;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    border-radius: 12px;
    background: rgba(255, 255, 255, 0.8);
  }

  &__title {
    margin-bottom: 16px;

    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
  }
}
