@import '../../../../../../style/variables.scss';

.subject-row {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;

  padding: 14px 12px;

  transition: background-color 0.2s;
  background-color: white;
  border: 1px solid transparent;
  cursor: pointer;

  &:not(:first-child) {
    border-top: 1px solid $border-color;
  }

  &__marker {
    min-width: 12px;
    height: 12px;

    border-radius: 50%;
  }

  &__name {
    flex-grow: 1;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    font-size: 16px;
    line-height: 24px;
  }

  .stats {
    display: flex;
    gap: 16px;
    min-width: 108px;

    font-size: 16px;
    line-height: 24px;
    font-weight: 700;

    &__change {
      display: flex;
      align-items: center;

      &--positive {
        color: #51CF66;
      }

      &--negative {
        color: #FF6B6B;
      }

      svg {
        width: 18px;
        height: 18px;
        margin-right: 2px;
      }
    }
  }

  &:hover,
  &--selected {
    border: 1px solid #F3D9FA;
    background-color: #FBF7FD;
    border-radius: 8px;

    & + * {
      border-top: none;
    }
  }

  &:hover &,
  &--selected & {
    &__name {
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 766px) {
  .subject-row {
    &__name, .stats {
      font-size: 14px;
    }
  }
}
