@import '../../../../../style/variables.scss';

.performance-diagram {
  display: grid;
  grid-template-columns: 384px 1fr;

  height: 630px;
  max-height: 630px;

  border-radius: 12px;
  border: 1px solid $border-color;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: white;

  &__chart-container,
  &__details-container {
    height: 630px;
  }

  &__chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 20px 4px 20px;

    border-right: 1px solid $border-color;
  }

  &__subjects {
    flex-shrink: 1;
    width: 336px;
    margin-top: 20px;
    overflow-y: auto;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    & > *:last-child {
      margin-bottom: 4px;
      margin-left: 12px;
    }
  }


}

@media screen and (max-width: 766px) {
  .performance-diagram-wrapper {
    margin-bottom: 12px;
  }
  .performance-diagram {
    grid-template-columns: unset;
    max-height: unset;
    height: fit-content;
    font-size: 14px !important;
    &__subjects {
      width: 100%;
      padding-bottom: 0;
      font-size: 14px;
    }

    &__chart-container {
      height: fit-content;
      padding: 16px 8px 20px 8px;
      border-right: none;
    }

    &__details-container {
      display: none;
    }
  }
}
