@import '../../../style/variables.scss';

.pdf-education-section {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 32px;
  background-color: white;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__title {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  &__header {
    border-top: 2px solid $border-color-pdf;
    border-bottom: 2px solid $border-color-pdf;
    padding: 24px 48px;
    height: 80px;
    display: flex;
  }
}
