@import '../../style/variables.scss';

.link-mode-error-page {
  max-width: 100%;
  max-height: 100%;
  padding: 12px;

  display: flex;
  flex-grow: 1;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  img {
    max-width: 100%;
    max-height: calc(100vh - 400px);
  }

  p,
  h4 {
    text-align: center;
  }

  p {
    color: $note-text;
  }
}

@media (max-width: 600px) {
  .link-mode-error-page.scrollable-container {
    margin-bottom: 0;
  }
}

