@import '../../../../style/variables.scss';

.pdf-diagnostic-card-wrapper {
  --card-bottom-margin: 16px;

  position: relative;

  display: flex;
  align-items: center;

  height: 100%;
  margin-bottom: var(--card-bottom-margin);

  &:not(:first-of-type) .list-year {
    display: none;
  }

  &:first-of-type .list-year {
    position: absolute;
    top: 16px;

    transform: rotate(270deg) translateY(-20px);

    opacity: 0.4;
    color: $border-color-pdf;
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    letter-spacing: 0.05em;
  }

  &__side {
    align-self: stretch;

    display: flex;
    justify-content: flex-end;

    position: relative;
    min-width: 20px;
    min-height: 100%;
    width: 48px;

    margin-right: 8px;
  }

  &__pin {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;

    border-radius: 50%;
    background-color: #b0b3c3;

    &::after {
      content: '';
      z-index: 2;

      position: absolute;
      width: 10px;
      height: 10px;

      border-radius: 50%;
      background-color: #ffffff;
    }
  }

  &__line {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: calc(var(--card-bottom-margin) * -1);

      width: 2px;
      background-color: $border-color-pdf;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:last-of-type &__line::before {
    bottom: 0;
  }
}
