.subject-themes-drawer {
  height: 100%;

  &__header {
    position: relative;

    .button-close {
      position: absolute;
      top: 12px;
      right: 16px;

      width: 24px;
      height: 24px;
      padding: 0;
    }
  }

  &__content {
    height: 100%;
    padding-top: 20px;
  }
}
