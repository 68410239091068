@import '../../../../style/variables.scss';

.multi-input-item {
  display: flex;
  align-items: center;

  &:not(:first-of-type) {
    margin-top: 14px;
  }

  .mobile-multi-input {
    width: calc(100% - 48px)
  }
  .mobile-multi-input__icon path {
    fill: $blue_primrose;
  }

  &__button--type-mobile {
    cursor: pointer;
  }
}


