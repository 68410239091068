@import '../../../style/variables.scss';

.delete-history-container {
  width: 100%;
  margin-top: 24px;

  .loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search-alert {
    color: $note-text;
  }
}
