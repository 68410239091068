@import '../../../style/variables.scss';

@media (max-width: 600px) {
  .achievement-form_combo {
    height: 48px;

    .MuiInputBase-root {
      height: 48px;
    }

    label {
      transform: translate(12px, 14px) scale(1);
      color: $note-text;
      background-color: white;

      &.MuiInputLabel-shrink {
        z-index: 2;
        padding: 0 4px;
        transform: translate(12px, -7px) scale(0.75);
      }

      &.Mui-required {
        &:after {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          top: 0;
          right: -12px;
          background-color: $red-rose_l2;
          background: url("../../../icons/RequiredFieldMarker.svg")
          }
      }
    }
  }
}


