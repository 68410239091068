@import '../../../style/variables.scss';

.pdf-doughnut-stats {
  display: flex;
  gap: 16px;

  height: 226px;

  &__completed {
    flex-shrink: 1;
  }

  &__aside {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.pdf-doughnut-stats .completed {
  width: 100%;

  padding: 24px 30px 28px 30px;
  background-color: white;
  border: 2px solid $border-color-pdf;

  &__title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    color: $main-text;
  }

  &__body {
    margin-top: 20px;

    display: flex;
    gap: 20px;
  }

  &__chart {
    width: 126px;
    height: 126px;
  }

  &__info-item {
    display: grid;
    grid-template-columns: auto minmax(max-content, 200px) auto;
    align-items: baseline;
    gap: 8px;

    width: 100%;
    max-width: 238px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__color-tip {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  &__rank {
    justify-self: flex-start;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__result {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
}

.pdf-doughnut-stats .best-percent {
  height: 105px;
  min-width: 448px;
  padding: 16px;

  background-color: white;
  border: 2px solid $border-color-pdf;

  display: grid;
  row-gap: 4px;
  align-items: center;

  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-template:
    "title chart"
    'result chart';

  &__title {
    grid-area: title;

    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $note-text-pdf;
  }

  &__result {
    grid-area: result;

    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
  }

  &__chart {
    justify-self: flex-end;
    grid-area: chart;

    width: 73px;
    height: 73px;
  }
}

.pdf-doughnut-stats .best-subject {
  height: 105px;
  min-width: 448px;
  padding: 16px;

  background-color: white;
  border: 2px solid $border-color-pdf;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  &__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $note-text-pdf;
  }

  &__result {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
  }
}
