@import '../../../style/variables.scss';

.section-visibility {
  position: relative;
  display: flex;
  align-items: center;
  height: 74px;

  background-color: #ffffff;
  overflow-y: scroll;

  &__item--active {
    svg .inner {
      fill: #ffffff;
    }
  }

  &__route-link {
    color: $main-text;
    text-decoration: none;

    &--active {
      color: #ffffff;
    }
  }

  .MuiList-root {
    display: flex;
    flex-direction: row;
    height: 50px;

    padding: 0;
  }

  .MuiListItem-root {
    display: flex;
    align-items: center;
    width: max-content;
    height: inherit;

    margin-right: 16px;
    padding-left: 12px;
    padding-right: 16px;
    background-color: #ffffff;
    border: 1px solid $control-stroke;
    border-radius: 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  .MuiListItemIcon-root {
    min-width: unset;
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .MuiListItemText-root .MuiTypography-root {
    margin-right: 12px;
    color: $main-text;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
  }
}
