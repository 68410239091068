@import '../../../../../../style/variables.scss';

.subject-overview {
  display: flex;
  justify-content: space-between;
  height: 124px;

  padding: 0 24px;
  padding-top: 20px;

  border-bottom: 1px solid $border-color;

  &__title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
  }

  &__result {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 60px;
    height: 60px;

    &::before {
      content: '';
      position: absolute;
      width: 70px;
      height: 70px;

      border-radius: 50%;
      background-color: white;
    }

    .exam-doughnut {
      width: 60px;
      height: 60px;

      .exam-doughnut__result {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
      }
    }
  }

  .subject-topic-details {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 12px;

    &__title {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: $note-text;
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }

    .exam-doughnut {
      width: 48px;
      height: 48px;

      .exam-doughnut__result {
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
      }
    }
  }
}

@media screen and (max-width: 766px) {
  .subject-overview {
    padding: 12px 20px 8px 20px;
    height: unset;
    align-items: flex-end;

    .subject-topic-details-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    &__result {
      width: 48px;
      height: 48px;

      &::before {
        width: 50px;
        height: 50px;
      }

      .exam-doughnut {
        width: 48px;
        height: 48px;
      }
    }
  }
}
