@import '../../../../style/variables.scss';

.MuiPopover-paper {
  &.select-topped {
      margin-top: -12px;
  }
}

.render-value {
  display: flex;
  align-items: center;
  .MuiSvgIcon-root {
    margin-right: 8px;
    width: 20px;
  }
}

.select {
  width: 100%;
  &__item.MuiMenuItem-root {
    border-radius: 4px;
    margin-bottom: 4px;
    .MuiSvgIcon-root {
      width: 20px;
      margin-right: 8px;
    }
    &.Mui-selected {
      background-color: $blue-primrose;
      color: white;
      .MuiSvgIcon-root {
        path {
          fill: white;
        }
      }
    }
  }

  &__item--marked.MuiMenuItem-root {
    margin-bottom: 16px;
    position: relative;
    overflow: visible;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $border-color;
      bottom: -12px;
    }
  }

  &__placeholder {
    display: block;
    padding: 8px 16px;
    color: $disable-text;
  }



}

@media (max-width: 600px) {
  .select {
    .MuiChip-root {
      max-width: 80%;
    }
  }
}
