@import '../../../../../style/variables.scss';

.interests-progress-bar {
  --height: 20px;

  display: grid;
  grid-template-columns: minmax(0, 200px) minmax(550px, 1fr) minmax(0, 200px);

  width: 100%;

  &__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 500px;
    height: 20px;
  }

  &__icon.MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    color: $control-stroke;
  }

  &__icon--active.MuiSvgIcon-root {
    color: $main-text;
  }

  &__icon--left {
    margin-right: 8px;
  }

  &__icon--right {
    margin-left: 8px;
  }
}

@media (max-width: 1365px) {
  .interests-progress-bar__nav {
    height: 16px;
  }
}

@media (max-width: 638px) {
  .interests-progress-bar {
    grid-template-columns: 1fr 200px 1fr;

    &__nav {
      min-width: initial;
    }
  }
}
