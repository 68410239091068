@import '../../../style/variables.scss';

.pdf-data-entity {
  background-color: white;
  padding: 20px 24px;
  margin-left: 56px;
  border: 1px solid $border-color-pdf;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  align-items: flex-start;


  &__date {
    margin-top: 16px;
  }

  &__name {
    display: flex;
    margin-bottom: 8px;
    word-wrap: break-word;
    flex-wrap: wrap;
    word-break: break-word;
  }

  &__info {
    width: 66%;
  }

  &__pin {
    position: absolute;
    left: -29px;
    top: calc(50% - 10px);
    background-color: $disable-text;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: white;
      top: 5px;
      left: 5px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: -20px;
    top: 0;
    bottom: -18px;
    width: 2px;
    background-color: $control-stroke;
  }

  &:last-of-type {
    &::before {
      bottom: 0;
    }
  }

  // год проведения (year) - должен отображаться только у первой сущности на странице
  &:first-of-type {
    .year {
      position: absolute;
      left: -56px;
      top: 12px;
      transform: rotate(-90deg) translate(0, -50%);
      color: $border-color-pdf;
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.05em;
    }
  }

  // год проведения (year) - должен отображаться только у первой сущности на странице
  &:not(:first-of-type) {
    .year {
      display: none;
    }
  }


  &__features-container {
    width: 100%;
    overflow-x: auto;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    text-transform: lowercase;
  }

  &__feature {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    height: 32px;
    background-color: $pdf-background;
    color: $note-text-pdf;
    border: 1px solid $border-color-pdf;
    border-radius: 4px;
    white-space: nowrap;
    padding: 4px 12px 4px 13px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;

    &--approve {
      margin-right: 0;
      padding: 0;
      color: #969696;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__title {
    margin-bottom: 2px;
  }

  &__stage {
    margin-bottom: 8px;
    font-weight: bold;
  }

  &__types {

    span {
      margin-right: 21px;
      position: relative;

      &:not(:first-of-type) {
        &::before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $control-stroke;
          left: -12px;
          top: 8px;
        }
      }

    }
  }

  .reward {
    width: 250px;
    min-height: 84px;
    background: $pdf-background;
    border-radius: 0px 0px 12px 12px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 32px;
    padding: 16px;
    padding-right: 64px;


    &__result {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 20px;
    }

    &__date {
      color: $note-text;
      line-height: 24px;
    }

    img {
      position: absolute;
      right: 12px;
      top: 0;
    }
  }
}

