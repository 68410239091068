@import '../../../../style/variables.scss';

.entity-actions-item {
  .entity-actions-item__menu-icon {
    width: 20px;
    height: 20px;

    path {
      fill: $note-text;
    }
  }
}
