@import '../../../../style/variables.scss';

.pdf-independent-diagnostics {

  &__title {
    font-size: 18px;
    line-height: 28px;
  }

  .bar-chart {
    &__title {
      color: $note-text-pdf;
    }
  }
}

.pdf-independent-diagnostics__title {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pdf-divider-diagnostics {
  height: 2px;
  margin: 32px -48px;

  background-color: $border-color-pdf;
}
