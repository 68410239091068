@import '../../../style/variables.scss';

.portfolio-data {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 32px;
  }

  &__name {
    color: $note-text;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__content-controls {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  }

  &__header-button {
    cursor: pointer;
    width: 52px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    background-color: white;
    border: 1px solid $border-color;
    border-radius: 8px 0px 0px 8px;

    &--right {
      transform: rotate(180deg);
    }

    &--disabled {
      opacity: 0.3;
    }
  }

  &__content {
    min-height: 100vh;
    width: 100%;
    padding: 20px 32px;
    background-color: $page-background;

    .MuiInput-root {
      width: 40%;
      margin-right: 16px;
      .MuiSelect-root {
        background-color: white;
      }
    }
    .btn-add {
      width: 25%;
    }
  }
  .MuiSelect-icon {
    width: 12px;
    height: 6px;
    top: calc(50% - 3px);
    right: 14px;
  }

  .loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
