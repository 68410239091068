@import 'style/variables.scss';

@keyframes circular-dotted-loader {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(0.16,0.16);
  } 100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1,1);
  }
}
.circular-dotted-loader div > div {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: $blue-primrose;
  animation: circular-dotted-loader 1s linear infinite;
}.circular-dotted-loader div:nth-child(1) > div {
  left: 76px;
  top: 44px;
  animation-delay: -0.9375s;
}
.circular-dotted-loader > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 82px 50px;
}.circular-dotted-loader div:nth-child(2) > div {
  left: 74px;
  top: 56px;
  animation-delay: -0.875s;
}
.circular-dotted-loader > div:nth-child(2) {
  transform: rotate(22.5deg);
  transform-origin: 80px 62px;
}.circular-dotted-loader div:nth-child(3) > div {
  left: 67px;
  top: 67px;
  animation-delay: -0.8125s;
}
.circular-dotted-loader > div:nth-child(3) {
  transform: rotate(45deg);
  transform-origin: 73px 73px;
}.circular-dotted-loader div:nth-child(4) > div {
  left: 56px;
  top: 74px;
  animation-delay: -0.75s;
}
.circular-dotted-loader > div:nth-child(4) {
  transform: rotate(67.5deg);
  transform-origin: 62px 80px;
}.circular-dotted-loader div:nth-child(5) > div {
  left: 44px;
  top: 76px;
  animation-delay: -0.6875s;
}
.circular-dotted-loader > div:nth-child(5) {
  transform: rotate(90deg);
  transform-origin: 50px 82px;
}.circular-dotted-loader div:nth-child(6) > div {
  left: 32px;
  top: 74px;
  animation-delay: -0.625s;
}
.circular-dotted-loader > div:nth-child(6) {
  transform: rotate(112.5deg);
  transform-origin: 38px 80px;
}.circular-dotted-loader div:nth-child(7) > div {
  left: 21px;
  top: 67px;
  animation-delay: -0.5625s;
}
.circular-dotted-loader > div:nth-child(7) {
  transform: rotate(135deg);
  transform-origin: 27px 73px;
}.circular-dotted-loader div:nth-child(8) > div {
  left: 14px;
  top: 56px;
  animation-delay: -0.5s;
}
.circular-dotted-loader > div:nth-child(8) {
  transform: rotate(157.5deg);
  transform-origin: 20px 62px;
}.circular-dotted-loader div:nth-child(9) > div {
  left: 12px;
  top: 44px;
  animation-delay: -0.4375s;
}
.circular-dotted-loader > div:nth-child(9) {
  transform: rotate(180deg);
  transform-origin: 18px 50px;
}.circular-dotted-loader div:nth-child(10) > div {
  left: 14px;
  top: 32px;
  animation-delay: -0.375s;
}
.circular-dotted-loader > div:nth-child(10) {
  transform: rotate(202.5deg);
  transform-origin: 20px 38px;
}.circular-dotted-loader div:nth-child(11) > div {
  left: 21px;
  top: 21px;
  animation-delay: -0.3125s;
}
.circular-dotted-loader > div:nth-child(11) {
  transform: rotate(225deg);
  transform-origin: 27px 27px;
}.circular-dotted-loader div:nth-child(12) > div {
  left: 32px;
  top: 14px;
  animation-delay: -0.25s;
}
.circular-dotted-loader > div:nth-child(12) {
  transform: rotate(247.5deg);
  transform-origin: 38px 20px;
}.circular-dotted-loader div:nth-child(13) > div {
  left: 44px;
  top: 12px;
  animation-delay: -0.1875s;
}
.circular-dotted-loader > div:nth-child(13) {
  transform: rotate(270deg);
  transform-origin: 50px 18px;
}.circular-dotted-loader div:nth-child(14) > div {
  left: 56px;
  top: 14px;
  animation-delay: -0.125s;
}
.circular-dotted-loader > div:nth-child(14) {
  transform: rotate(292.5deg);
  transform-origin: 62px 20px;
}.circular-dotted-loader div:nth-child(15) > div {
  left: 67px;
  top: 21px;
  animation-delay: -0.0625s;
}
.circular-dotted-loader > div:nth-child(15) {
  transform: rotate(315deg);
  transform-origin: 73px 27px;
}.circular-dotted-loader div:nth-child(16) > div {
  left: 74px;
  top: 32px;
  animation-delay: 0s;
}
.circular-dotted-loader > div:nth-child(16) {
  transform: rotate(337.5deg);
  transform-origin: 80px 38px;
}
.circular-dotted-loader-container {
  width: 34px;
  height: 34px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.circular-dotted-loader {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.34);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.circular-dotted-loader div { box-sizing: content-box; }
