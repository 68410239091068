@import '../../../../../../style/variables.scss';

.tile {
  display: flex;
  padding: 12px 16px;

  border-radius: 12px;
  border: 2px solid transparent;
  outline-offset: -1px;
  outline: 1px solid $border-color;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

  &__text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;
  }
}

.average-result-tile {
  height: 90px;

  &__title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    color: $main-text;
  }

  &__description {
    font-weight: 400;
    color: $note-text;
  }

  .exam-doughnut {
    width: 64px;
    height: 64px;

    .exam-doughnut__result {
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
    }
  }
}

.subject-result-tile {
  height: 82px;
  cursor: pointer;
  transition: background-color 0.2s;

  &__description {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $main-text;
  }

  .exam-doughnut {
    width: 58px;
    height: 58px;

    .exam-doughnut__result {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }
  }

  &--selected, &:hover {
    outline: none;
    border: 2px solid $violet-border;
    background-color: $violet-lite;
    box-shadow: none;
  }

  &--selected &, &:hover & {
    &__description {
      font-weight: 700;
    }
  }
}


@media screen and (max-width: 766px) {
  .average-result-tile {
    background-color: $white;
  }
}
