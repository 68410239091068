@import '../../../style/variables';
@import '../../../style/mixins';

.dialog {

  &--type-delete {
    .MuiDialogContent-root.MuiDialogContent-root {
      padding-top: 0;
      margin-top: 0;
    }
  }
  &__top-controls {
    position: sticky;
    top: 0;
  }

  &__description {
    margin-top: 16px;

    font-size: 16px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h6 {
    margin: 0;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
  }

  &__close-icon.MuiIconButton-root {
    padding: 0;
  }

  &__mobile-icon.MuiIconButton-root {
    display: none;
    padding: 0;
  }

  &__button--delete.MuiButton-containedPrimary {
    background-color: $red-rose;

    &:hover,
    &:active,
    &:focus {
      background-color: $red-rose_l4;
    }
  }

  &.dialog-setting,
  &.info-dialog {
    .dialog__title {
      display: none;
    }
    .dialog__description{
      display: none;
    }

    .dialog__mobile-icon {
      display: block;
    }

    .MuiDialogContent-root{
      padding: 0px;
      margin-top: 0px;
    }

    .dialog-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        margin-bottom: 20px;
      }

      span {
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: $main-text;
      }
    }



    .dialog-paper.dialog-paper {
      padding: 16px 32px 24px 32px;
      padding-top: 12px;
    }
  }

  &.info-dialog {
    h5 {
      font-weight: 700;
      margin-bottom: 6px;
    }
  }

  .dialog-avatar {
    .dialog-paper.dialog-paper {
      width: 604px;
      max-width: 604px;

      .MuiDialogContent-root {
        padding-top: 0;
        margin-top: 32px;
        padding: 0;
      }
    }
    .divider {
      position: absolute;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $border-color;
      &--bottom {
        bottom: 111px;
      }
    }
    &__content {
      padding: 32px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &::after {
        content: '';
        flex: 0 1 50%;
      }
    }
    &__item {
      cursor: pointer;
      width: 120px;
      height: 120px;
      padding: 0;
      margin-bottom: 20px;
      border: none;
      display: block;
      outline: none;
      background-color: white;
      opacity: 1;

      &--unselected {
        opacity: 0.6;
      }

      &--selected {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 120px;
          height: 120px;
          border: 6px solid $fir-green;
          box-sizing: border-box;
          border-radius: 800px;
        }
      }
    }
    &__status {
      display: none;
      position: absolute;
      justify-content: center;
      align-items: center;
      z-index: 1;
      content: '';
      width: 32px;
      height: 32px;
      left: 78px;
      top: 88px;
      background-color: $fir-green;
      border-radius: 800px;
      &--selected {
        display: flex;
        .MuiSvgIcon-root {
          width: 18px;
        }
      }
    }
    &__alert {
      margin-top: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $note-text;
    }
    .dialog__actions.MuiDialogActions-root {
      padding-top: 32px;
    }
  }

  .btn-drag-container {
    border: none;
    outline: none;
    display: none;
    width: 64px;
    height: 4px;
    border-radius: 100px;
    background-color: $control-stroke;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;

    .btn-drag-active-area {
      z-index: 2;
      position: absolute;
      border: none;
      outline: none;
      top: 0;
      right: 0;
      width: 100%;
      height: 60px;
      background: transparent;
    }
  }
}

@include breakpoint-down(commonSm) {
  .dialog {
    h6 {
      font-size: 18px;
      line-height: 28px;
    }

    &__description {
      text-align: center;
      margin-top: 12px;
      font-size: 16px;
    }

    .btn-drag-container {
      display: block;
    }
    &__actions {
      display: flex;
      flex-direction: column;
    }

    .dialog__button.dialog__button {
      width: 100%;
      margin-top: 8px;
      margin-left: 0;
    }

    &__close-icon.MuiIconButton-root {
      display: none;
    }

    & .dialog-paper.dialog-paper.dialog-paper {
      width: 100%;
      max-width: unset;
      padding: 12px;
      padding-bottom: 32px;
      border-radius: 16px 16px 0 0;
      margin: 0;
      margin-top: auto;
    }

    &__title {
      text-align: center;
      justify-content: center;
      h2.MuiTypography-root.MuiTypography-h6 {
        justify-content: center;
      }
    }
  }
}

