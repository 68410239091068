@import '../../../style/variables.scss';

.visibility-history {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;
    padding: 0 32px;

    background-color: white;
    border-top: 2px solid $border-color;
    border-bottom: 2px solid $border-color;
  }
  &__title {
    color: $heading;
  }
}
