@import '../../../style/variables';

.input {
  input {
    &::-webkit-input-placeholder {
      color: $disable-text;
      opacity: 1;
    } ;
    &::-moz-placeholder {
      color: $disable-text;
      opacity: 1;
    };

    &:-moz-placeholder {
      color: $disable-text;
      opacity: 1;
    };

    &:-ms-input-placeholder {
      color: $disable-text;
      opacity: 1;
    };

    &:focus::-webkit-input-placeholder {
      color: 'transparent'
    };

    &:focus::-moz-placeholder {
      color: 'transparent'
    };

    &:focus:-moz-placeholder {
      color: 'transparent'
    };

    &:focus:-ms-input-placeholder {
      color: 'transparent'
    }
  }
  textarea {
    &::-webkit-input-placeholder {
      color: $disable-text;
      opacity: 1;
    } ;
    &::-moz-placeholder {
      color: $disable-text;
      opacity: 1;
    };

    &:-moz-placeholder {
      color: $disable-text;
      opacity: 1;
    };

    &:-ms-input-placeholder {
      color: $disable-text;
      opacity: 1;
    };

    &:focus::-webkit-input-placeholder {
      color: 'transparent'
    };

    &:focus::-moz-placeholder {
      color: 'transparent'
    };

    &:focus:-moz-placeholder {
      color: 'transparent'
    };

    &:focus:-ms-input-placeholder {
      color: 'transparent'
    }
  }
}
