@import '../../../../../style/variables.scss';

/* action popper */
.interests-action-popper {
  z-index: 3;
  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }

  .MuiFormControlLabel-label.MuiTypography-root {
    margin: 0;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 14px;

    padding: 16px;
    border: 1px solid $border-color;

    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    background-color: white;
    border-radius: 8px;
  }

  &__title {
    margin-bottom: 2px;

    color: $note-text;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &__action-item {
    display: flex;
    align-items: center;
  }

  &__action-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-left: 12px;
    margin-right: 12px;
  }

  img {
    margin-left: auto;
  }
}

/* action drawer */
.interests-action-drawer {
  .MuiPaper-root {
    padding: 32px 24px;
    border-radius: 16px 16px 0 0;
  }

  &__interest-name {
    display: flex;
    align-items: center;

    font-style: 16px;
    line-height: 24px;
    font-weight: 700;
  }

  &__caption {
    margin-top: 16px;

    font-size: 14px;
    line-height: 20px;
    color: $note-text;
  }

  &__section-select {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
  }

  &__button.MuiButtonBase-root.MuiButton-root {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 36px;
    width: 100%;
    padding: 0;
    padding-right: 8px;

    border: none;
    border-bottom: 1px solid $border-color;
    border-radius: 0;
    background-color: #ffffff;
    box-shadow: unset;

    font-size: 14px;
    line-height: 20px;

    color: $main-text;

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__exit-button.MuiButtonBase-root.MuiButton-root {
    display: flex;
    align-items: center;

    width: max-content;
    height: 36px;
    padding: 0;

    border: none;
    background-color: #ffffff;
    box-shadow: unset;

    font-size: 14px;
    line-height: 20px;
    font-weight: 500;

    color: $note-text;

    img {
      width: 20px;
      height: 20px;
    }
  }

  &__actions {
    margin-top: 16px;
  }

  &__action-item {
    height: 36px;
    display: flex;
    align-items: center;
  }

  &__label {
    font-size: 14px;
    line-height: 20px;
  }
}
